import moment from "moment";

export const dadesTemporadaFormatterFromApi = (row) => {

	let r = []
	r.push(
		{
			id: row.id,
			titol_principal: row.txtTitolPrincipal,
			subtitol: row.txtSubtitol,
			any_lsda: row.txtAnyLSDA,
			supersardana: row.bSupersardana === '1',
			data_inici: row.dInici,
			data_final_elim: row.dFinalEliminatories,
			data_inici_registre: row.dIniciRegistre,
			data_final_registre: row.dFinalRegistre,
			data_concert: row.dConcert,
			url_final_tickets: row.urlFinalTickets,
			url_final_streaming: row.urlFinalStreaming,
		}
	)

	return {
		rows: r,
	}
};

export const dadesTemporadaFormatterToApi = (row) => {
	const forApiRows = {
		id: row.id,
		txtTitolPrincipal: row.titol_principal,
		txtSubtitol: row.subtitol,
		txtAnyLSDA: row.any_lsda,
		bSupersardana: row.supersardana,
		dInici: moment(row.data_inici).format("YYYY-MM-DD"),
		dFinalEliminatories: moment(row.data_final_elim).format("YYYY-MM-DD"),
		dIniciRegistre: moment(row.data_inici_registre).format("YYYY-MM-DD"),
		dFinalRegistre: moment(row.data_final_registre).format("YYYY-MM-DD"),
		dConcert: moment(row.data_concert).format("YYYY-MM-DD"),
		urlFinalTickets: row.url_final_tickets,
		urlFinalStreaming: row.url_final_streaming,
	};
	console.info("forApiRows", forApiRows)
	return forApiRows
};
