import React, {useState} from "react";
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';

import './PDFViewerStyles.css';

const PDFViewer = ({file}) => {
	const options = {
		cMapUrl: 'cmaps/',
		cMapPacked: true,
	};

	const [numPages, setNumPages] = useState(null);

	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages);
	}

	return (
		<div className="Example">
			<div className="Example__container">
				<div className="Example__container__document">
					<Document
						file={file}
						onLoadSuccess={onDocumentLoadSuccess}
						options={options}
					>
						{
							Array.from(
								new Array(numPages),
								(el, index) => (
									<Page
										key={`page_${index + 1}`}
										pageNumber={index + 1}
									/>
								),
							)
						}
					</Document>
				</div>
			</div>
		</div>
	)
}

export default PDFViewer;
