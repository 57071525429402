import React, {useEffect, useState} from "react";
import BodyNewSeasonScreen from "../../components/composed/BodyNewSeason/BodyNewSeason";
import {Button, FormControlLabel, makeStyles, Snackbar, Switch} from "@material-ui/core";
import EditableTable from "../../components/composed/EditableTable/EditableTableComponent/EditableTable";
import {calendarColumns, createNewRowCalendari} from "./calendarContent";
import {getCalendariService, updateCalendariService} from "../../data/services/calendariService";
import {SNACKBAR_MUI_TYPES, SNACKBAR_STRINGS, SNACKBAR_TYPES} from "../../utils/constants";
import Alert from "@material-ui/lab/Alert";
import Prompt from "react-router-dom/es/Prompt";
import moment from "moment";

const CalendariScreen = () => {

	const [hasChanged, setHasChanged] = useState(false);

	const [rows, setRows] = useState([]);
	const [autoSave, setAutoSave] = useState(true);
	const [lastSaved, setLastSaved] = useState("");
	const [columns, setColumns] = useState([]);

	const [eliminatories, setEliminatories] = useState([]);

	const [sardanesAvailable, setSardanesAvailable] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("info");
	const [snackBarText, setSnackBarText] = useState("snackBar");
	const useStyles = makeStyles({
		root: {
			"& .orange": {
				backgroundColor: "orange",
			},
		},
	});

	const classes = useStyles();


	useEffect(() => {
		setIsLoading(true)
		getCalendariService()
			.then(resValues => {
				console.log("resValues", resValues)
				setEliminatories(resValues.eliminatories);
				const colTipusIndex = calendarColumns.findIndex(item => item.field === "tipus")
				calendarColumns[colTipusIndex] = {
					...calendarColumns[colTipusIndex],
					valueOptions: resValues.eliminatories
				}
				setColumns(calendarColumns)
				setRows(resValues.rows)
				setSardanesAvailable(resValues.sardanes)
				setIsLoading(false)
			})
			.catch(err => {
				console.error("[CalendariScreen] error fetching calendar rows", err)
			})
	}, [])

	const saveTable = (silent = false, refreshContent = true, editedRows = null) => {
		//start loader
		if (!silent) setIsLoading(true)
		updateCalendariService(editedRows ? editedRows : rows)
			.then(resValues => {
				if (refreshContent) {
					setColumns(calendarColumns)
					setRows(resValues.rows)
					setSardanesAvailable(resValues.sardanes)
				}
				if (!silent) {
					setIsLoading(false)
					showSetupSnackBar(SNACKBAR_TYPES.SUCCESS)
				}
				setLastSaved("Últim desat a les " + moment().format("HH:mm:ss"))
			})
			.catch(err => {
				console.error("[tipusEliminatoriesScreen] error send rows", err)
				if (!silent) {
					showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
				}
				setIsLoading(false)
			})
		if (refreshContent) {
			setHasChanged(false)
		}
	};

	const showSetupSnackBar = (type) => {

		switch (type) {
			case SNACKBAR_TYPES.SUCCESS:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_GET_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_SEND_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_SEND_DATA)
				setShowSnackBar(true)
				break

			default:
				setSnackBarType("info")
				setSnackBarText("default switch")
				setShowSnackBar(true)

		}
	}

	const handleEditedRows = (editedRows, row_id) => {
		let row_index = editedRows.findIndex(r => r.id === row_id)
		if (row_index > 0) {
			if (editedRows[row_index]["tipus"] !== editedRows[row_index-1]["tipus"]) {
				editedRows[row_index]["nOrdreTipus"] = 1
			}
			else {
				editedRows[row_index]["nOrdreTipus"] = parseInt(editedRows[row_index-1]["nOrdreTipus"]) + 1
			}
			//Add next friday as final date if init date is monday
			let friday = 4
			if (moment(editedRows[row_index]["dataInici"]).weekday() === friday) {
				let nextMonday = moment(editedRows[row_index]["dataInici"]).add(3, 'days')
				editedRows[row_index]["dataFinal"] = nextMonday
			}
		}
		setRows(editedRows)
		setHasChanged(true)
		console.info("[CalendariScreen] edited rows", editedRows)

		if (autoSave) saveTable(true, true, editedRows);
	}

	const handleAddNewRow = () => {
		const lastRow = rows.length >= 1 ? rows[rows.length-1] : null;
		return createNewRowCalendari(lastRow, sardanesAvailable, eliminatories, rows)
	}

	return (
		<BodyNewSeasonScreen>
			<Prompt
				when={hasChanged}
				message={(location, action) => {
					return location.pathname === window.location.pathname
						? true
						: `Tens canvis sense desar, segur que vols sortir?`
				}}
			/>

			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<FormControlLabel
					control={<Switch
						checked={autoSave}
						onChange={() => setAutoSave(!autoSave)}
						name="autosave"
						inputProps={{'aria-label': 'secondary checkbox'}}
					/>}
					label={"Autodesar " + lastSaved}
				/>

				<Button color={'primary'} variant={'contained'} disabled={!hasChanged}
						onClick={() => saveTable()}>Desar</Button>
			</div>
			<EditableTable
				rows={rows}
				columns={columns}
				editable={true}
				deletable={true}
				insertable={true}
				onRowAdded={() => setHasChanged(true)}
				onRowDeleted={() => setHasChanged(true)}
				onEditedTable={(editedRows, row_id) => handleEditedRows(editedRows, row_id)}
				addNewRow={handleAddNewRow}
				isLoading={isLoading}
			/>
			<div dangerouslySetInnerHTML={{__html:`
				
				<p><strong>Instruccions:</strong></p>
				<ul>
				<li style="font-weight: 400;" aria-level="1"><strong>Ordre emissi&oacute; (O. em.): </strong><span style="font-weight: 400;">Ordre seq&uuml;encial en que s&rsquo;emetran les eliminat&ograve;ries. Des d&rsquo;1 fins al nombre total de programes de que consta l&rsquo;edici&oacute; d&rsquo;aquell any. Pot no correspondre&rsquo;s amb l&rsquo;ordre esperat en edicions on calgui emetre alguna edici&oacute; fora de les dates prestablertes, per exemple per problemes t&egrave;cnics.</span></li>
				<li style="font-weight: 400;" aria-level="1"><strong>Ordre resultats (O. res.): </strong><span style="font-weight: 400;">Ordre seq&uuml;encial en que cal mostrar els resultats de les eliminat&ograve;ries. Sol coincidir amb Ordre emissi&oacute; excepte quan ha calgut emetre alguna eliminat&ograve;ria fora de l&rsquo;ordre habitual. Per exemple, l&rsquo;any 2017, la 3a eliminat&ograve;ria es va haver d&rsquo;emetre unes setmanes m&eacute;s tard per culpa de problemes t&egrave;cnics. Aix&ograve; va afectar Ordre Emissi&oacute; per&ograve; no Ordre resultats.</span></li>
				<li><strong>Num. ordre tipus (N. tip.): </strong><span style="font-weight: 400;">N&uacute;mero d&rsquo;ordre dins del tipus actual d&rsquo;eliminat&ograve;ria. Es reinicia a 1 cada cop que es canvia de tipus. Per exemple, si despr&eacute;s de la 8a eliminat&ograve;ria ve la 1a semifinal, despr&eacute;s de l&rsquo;entrada amb Num. ordre tipus = 8 i Tipus = Eliminat&ograve;ries, ha de venir l&rsquo;entrada amb Num. ordre tipus = 1 i Tipus = Semifinals</span></li>					
				<li><strong>Sardanes: </strong><span style="font-weight: 400;">És molt important seleccionar les sardanes per l'ordre que van a les eliminatòries.</span></li>					
				<li><strong>D. ini.</strong>: Data d'inici.</li>
				<li><strong>D. fi.</strong>: Data final.</li>
				</ul>
			`}} />

			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>
		</BodyNewSeasonScreen>
	)
};

export default CalendariScreen;
