import React, {useState} from "react";
import {Tab, Tabs} from "@material-ui/core";
import {useLocation} from "react-router-dom";

const TabBar = ({onChange, tabs, currentValue}) => {

	const [value, setValue] = useState(currentValue);
	const location = useLocation()

	function a11yProps(index) {
		return {
			id: `scrollable-auto-tab-${index}`,
			"aria-controls": `scrollable-auto-tabpanel-${index}`,
		};
	}

	const handleOnClick = (tab) => {
		onChange(tab.route);
		if (location.pathname === tab.value) {
			setValue(tab.value);
		}
	}

	return (
		<Tabs
			indicatorColor="primary"
			value={value}
			style={{marginBottom: 12}}
			variant="scrollable"
			textColor="primary"
			scrollButtons="auto"
			aria-label="Tabs"
		>
			{tabs.map((tab, index) => {
				return <Tab
					key={"tab"+index}
					onClick={() => {
						handleOnClick(tab)
					}}
					label={tab.label}
					{...a11yProps(value)}
				/>
			})}

		</Tabs>
	)

};

export default TabBar;
