import React, {useEffect} from "react";
import {logoutAPI} from "../../data/api/authApi";


const Logout = ({history}) => {

	useEffect(() => {
		let res = window.confirm("Segur que vols fer logout?")
		if (!res) {
			history.goBack();
		}
		else {
			logoutAPI()
				.then(r => {
					history.push("/login")
				})
				.catch()
		}
	}, [])

	return (
		<>

		</>


	)
}

export default Logout;
