export const CONCERT_ACTIONS = {
	OPEN: "obrir_votacio",
	CLOSE: "tancar_votacio",
	PUBLISH: "publicar"
}
export const CONCERT_PREMIS_TYPES = {
	RESULTATS_PREMI_POPULAR: 'resultats_premi_popular',
	RESULTATS_PREMI_CRITICA: 'resultats_premi_critica',
	RESULTATS_PREMI_JOVENTUT: 'resultats_premi_joventut',
	ALL: 'all'
};

export const RESULTS_TABLE_TYPES = {
	TABLE_PREMI_POPULAR: 'premiPopularTable',
	TABLE_PREMI_CRITICA: 'premiCriticaTable',
	TABLE_PREMI_JOVENTUT: 'premiJoventutTable'
};

export const POSICIONS_ORDRE_SARDANES = {
	GUANYADORA: {
		order: 1,
		name: "Guanyadora"
	},
	ACCESSIT: {
		order: 2,
		name: "Accèssit"
	}
};

export const SNACKBAR_STRINGS = {
	SUCCESS: "S'han desat les dades correctament",
	SUCCESS_CLIPBOARD: "Adreçes de correu copiades correctament",
	SUCCESS_NEW_TEMPORADA: "Temporada creada correctament",
	ERROR_GET_DATA: 'Hi ha hagut un error al rebre les dades',
	ERROR_SEND_DATA: 'Hi ha hagut un error al desar les dades',
};

export const SNACKBAR_TYPES = {
	SUCCESS: "success",
	SUCCESS_CLIPBOARD: "success_clipboard",
	SUCCESS_NEW_TEMPORADA: "success_new_temporada",
	ERROR_GET_DATA: "error_get_data",
	ERROR_SEND_DATA: "error_send_data",
};

export const SNACKBAR_MUI_TYPES = {
	SUCCESS: "success",
	ERROR: "error",
	WARNING: "warning",
	INFO: "info",
};


export const CURRENT_WORKING_TEMPORADA_ID = "CURRENT_WORKING_TEMPORADA_ID";
export const USERNAME = "USERNAME";
