import axiosService from "../../services/axiosService";

export const getTipusEliminatoriesApi = (year = "working") => {
	return axiosService.get(
		"/temporada_detalls/" + year + "?s=tipus_elim"
	)
}

export const updateTipusEliminatoriesApi = (eliminatoriesRows, year = "working") => {
	return axiosService.post(
		"/temporada_detalls/" + year + "?s=tipus_elim", eliminatoriesRows
	)
}

