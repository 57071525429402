import React from "react";
import Body from "../../Layout/Body/Body";
import TabBar from "../../unit/TabBar/TabBar";
import {withRouter} from "react-router-dom";

const tabs = [
	{
		route: "/editar-temporada/dades-temporada",
		label: "Dades temporada",
		value: 0
	},
	{
		route: "/editar-temporada/tipus-eliminatories",
		label: "Tipus eliminatòries",
		value: 1
	},
	{
		route: "/editar-temporada/sardanes",
		label: "Sardanes",
		value: 2
	},
	{
		route: "/editar-temporada/calendari",
		label: "Calendari",
		value: 3
	},
	{
		route: "/editar-temporada/fitxers",
		label: "Fitxers",
		value: 4
	},
	{
		route: "/editar-temporada/llistat-emisores",
		label: "Llistat emissores",
		value: 5
	},
	{
		route: "/editar-temporada/llistat-collaboradors",
		label: "Llistat col·laboradors",
		value: 6
	},
	{
		route: "/editar-temporada/sardanes-classificades",
		label: "Sardanes classificades",
		value: 7
	},
];

const BodyNewSeasonScreen = ({children, history, location}) => {

	const currentPath = location.pathname;
	const tab = tabs.find(item => item.route === currentPath);
	const value = tab.value;


	const handleOnChange = (route) => {
		history.push(route);
	};

	return (
		<Body>
			<TabBar currentValue={value} tabs={tabs} onChange={handleOnChange}/>
			{children}
		</Body>
	)
};

export default withRouter(BodyNewSeasonScreen);
