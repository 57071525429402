import axiosService from "../../services/axiosService";

export const getEmissoresApi = (year = "working") => {
	return axiosService.get(
		"/temporada_detalls/" + year + "/?s=llistat_emissores"
	)
}

export const updateEmissoresApi = (emissoresRows, year = "working") => {
	console.info("emissoresRows", emissoresRows)
	return axiosService.post(
		"/temporada_detalls/" + year + "/?s=llistat_emissores", emissoresRows
	)
}
