import React from "react";
import "./App.css";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import MainPage from "./screens/MainPage/MainPage";
import Login from "./screens/Login/Login";
import ConcertsScreen from "./screens/ConcertsScreen/ConcertsScreen";
import Usuaris from "./screens/Usuaris/Usuaris";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import Body from "./components/Layout/Body/Body";
import TipusEliminatoriesScreen from "./screens/TipusEliminatoriesScreen/TipusEliminatoriesScreen";
import SardanesScreen from "./screens/SardanesScreen/SardanesScreen";
import CalendariScreen from "./screens/CalendariScreen/CalendariScreen";
import FitxersScreen from "./screens/FitxersScreen/FitxersScreen";
import LlistatEmisoresScreen from "./screens/LlistatEmisoresScreen/LlistatEmisoresScreen";
import AddSeasonScreen from "./screens/AddSeasonScreen/AddSeasonScreen";
import FasesRegularsScreen from "./screens/FasesRegulars/FasesRegularsScreen";
import FaseRegularDetailScreen from "./screens/FaseRegularDetail/FaseRegularDetailScreen";
import moment from "moment";
import 'moment/locale/ca'
import Logout from "./screens/Logout/Logout";
import RecoverPassword from "./screens/Login/RecoverPassword";
import LlistatCollaboradorsScreen from "./screens/LlistatCollaboradorsScreen/LlistatCollaboradorsScreen";
import DadesTemporadaScreen from "./screens/DadesTemporadaScreen/DadesTemporadaScreen";
import SardanesClassificadesScreen from "./screens/SardanesClassificadesScreen/SardanesClassificadesScreen";

const theme = createTheme({
	palette: {
		primary: {
			main: "#FF8429",
			light: "#FF8429",
			dark: "#FF8429",
			contrastText: "#FFFFFF",
		},
		secondary: {
			light: "#FF8429",
			main: "#FF8429",
			dark: "#FF8429",
			contrastText: "#FFFFFF",
		},
		error: {
			main: '#e52020',
			light: "#FF8429",
			dark: "#FF8429",
			contrastText: "#FFFFFF",
		},
		success: {
			main: '#91d91c',
			light: "#FF8429",
			dark: "#FF8429",
			contrastText: "#FFFFFF",
		}
	},
});

const view404 = () => {
	return <Body>Aquesta ruta no s'ha trobat</Body>;
};

function App() {
	moment.locale('ca');
	return (
		<Router>
			<ThemeProvider theme={theme}>
				<Switch>
					<Route exact path="/" component={MainPage}/>

					<Route exact path="/fases-regulars" component={FasesRegularsScreen}/>
					<Route exact path="/fases-regulars/detail/:idElim*" component={FaseRegularDetailScreen}/>

					<Route exact path="/editar-temporada/dades-temporada" component={DadesTemporadaScreen}/>
					<Route exact path="/editar-temporada/tipus-eliminatories" component={TipusEliminatoriesScreen}/>
					<Route exact path="/editar-temporada/sardanes" component={SardanesScreen}/>
					<Route exact path="/editar-temporada/calendari" component={CalendariScreen}/>
					<Route exact path="/editar-temporada/fitxers" component={FitxersScreen}/>
					<Route exact path="/editar-temporada/llistat-emisores" component={LlistatEmisoresScreen}/>
					<Route exact path="/editar-temporada/llistat-collaboradors" component={LlistatCollaboradorsScreen}/>
					<Route exact path="/editar-temporada/sardanes-classificades"
						   component={SardanesClassificadesScreen}/>
					<Route exact path="/nova-temporada" component={AddSeasonScreen}/>


					<Route exact path="/login" component={Login}/>
					<Route exact path="/recuperar-contrasenya" component={RecoverPassword}/>
					<Route exact path="/recuperar-contrasenya/:token" component={RecoverPassword}/>
					<Route exact path="/logout" component={Logout}/>
					<Route exact path="/concerts" component={ConcertsScreen}/>
					<Route exact path="/usuaris" component={Usuaris}/>
					<Route exact path="*" component={view404}/>
				</Switch>
			</ThemeProvider>
		</Router>
	);
}

export default App;
