export const emissoresColumns = [
	{field: "id", headerName: "ID", hide: true},
	{
		field: "nom",
		width: 240,
		headerName: "Nom",
		type: "string",
	},
	{
		field: "email",
		width: 300,
		headerName: "Correu electrònic de l'informador",
		type: "string",
	},
	{
		field: "responsable_emissora",
		width: 250,
		headerName: "Responsable emissora",
		type: "string",
	},
	{
		field: "email_alternatiu",
		width: 300,
		headerName: "Correu electrònic alternatiu",
		type: "string",
	},
	{
		field: "nom_programa",
		width: 220,
		headerName: "Nom del programa",
		type: "string",
	},

];

export const emissoresRows = [
	{
		id: 1,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",


	},
	{
		id: 2,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 3,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 4,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 5,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 6,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 7,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 8,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 9,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 10,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 11,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 12,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 13,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 14,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 15,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 16,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 17,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},
	{
		id: 18,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",
		responsable_emissora: "Antoni",
		email_alternatiu: "alterterrassa@gmail.com",
		nom_programa: "Programa 1",

	},

];
