import Button from "@material-ui/core/Button";
import WebIcon from '@material-ui/icons/Web';
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import {Cancel, Check} from "@material-ui/icons";
import {simulationFasesRegularsService} from "../../data/services/FasesRegulars/FasesRegularsService";
import {Tooltip} from "@material-ui/core";
import React from "react";

const getCellClass = (params) => {

	const {dataInici, dataFinal} = params.row;
	if (!dataInici && !dataFinal) return "";
	const week = new moment(dataInici).week();
	const weekFinal = new moment(dataFinal).week();
	const actualWeek = new moment().week();

	if (week === actualWeek) {
		return "blue";
	}
	if (weekFinal === actualWeek) {
		return "blue";
	}
}

export const faseRegularColumns = [
	{field: "id", headerName: "ID", hide: true},
	{
		field: 'actions',
		headerName: 'Accions',
		renderCell: (params) => {
			let url = params.row.simulacioURL;
			let date = moment(params.row.dataInici, 'YYYY/MM/DD');
			let day = date.format('D');
			let month = date.format('M');
			let year = date.format('YYYY')

			const handleSimulation = () => {
				simulationFasesRegularsService(url, day, month, year).then(r => {
					console.log(r)
				})
			}
			return (
				<div style={{zIndex: 100}}>
					<Tooltip title={"Veure simulació"}>
						<Button onClick={() => handleSimulation()}><WebIcon/></Button>
					</Tooltip>
				</div>
			)
		},
		sortable: false,
		width: 90,
		filterable: false,
		headerAlign: 'center',
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		cellClassName: (params) => {
			return getCellClass(params);
		},
	},
	{
		field: "ordreEmissio",
		sortable: false,
		width: 80,
		filterable: false,
		headerName: "Ordre",
		type: "",
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		cellClassName: (params) => {
			return getCellClass(params);
		},
	},
	{
		field: "tipus",
		sortable: false,
		width: 103,
		filterable: false,
		headerName: "Tipus",
		type: "string",
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		cellClassName: (params) => {
			return getCellClass(params);
		},
	},
	{
		field: "sardanes",
		headerName: "Sardanes (max 6)",
		renderCell: (params) => {
			return (
				<div style={{display: 'flex', overflowX: "auto", width: '100%', overflowY: "hidden"}}>
					{params.row.sardanes.map((sardana, index) => (
						<Tooltip title={params.row.sardanes.map((el, ind) => (

							<p style={{fontSize: "13px", margin: 0}}>
								{ind + 1 + " - " + el.Titol}
							</p>
						))}>
							<Chip style={{margin: 4}} label={sardana.Titol}/>
						</Tooltip>
					))}
				</div>
			)
		},
		sortable: false,
		minWidth: 600,
		flex: 1,
		filterable: false,
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		cellClassName: (params) => {
			return getCellClass(params);
		},

	},
	{
		field: "dataInici",
		width: 120,
		headerName: "Data inici",
		type: "string",
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		valueFormatter: (params) => {
			const valueFormatted = moment(params.value).format("dd. DD/MM/YYYY")
			return `${valueFormatted}`;
		},
		cellClassName: (params) => {
			return getCellClass(params);
		},
	},
	{
		field: "dataFinal",
		width: 120,
		headerName: "Data final",
		type: "string",
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		valueFormatter: (params) => {
			const valueFormatted = moment(params.value).format("dd. DD/MM/YYYY")
			return `${valueFormatted}`;
		},
		cellClassName: (params) => {
			return getCellClass(params);
		},
	},
	{
		field: "published",
		headerName: "Publicat",
		type: "boolean",
		sortable: false,
		width: 85,
		filterable: false,
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		cellClassName: (params) => {
			return getCellClass(params);
		},
	},
	{
		field: "validated",
		headerName: "Validació",
		renderCell: (params) => {
			if (params.row.validated.value) {
				return <Chip style={{zIndex: 100}} label={'Correcte'} icon={<Check />}/>
			} else {
				return (
					<Tooltip title={"Detalls validació"}>
						<Chip style={{zIndex: 100}} style={{cursor: "pointer"}} label={'Incompleta'} color={"secondary"}
							  icon={<Cancel/>}/>
					</Tooltip>
				)
			}
		},
		sortable: false,
		width: 125,
		filterable: false,
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		cellClassName: (params) => {
			return getCellClass(params);
		},
	},
];
