import axiosService from "../../services/axiosService";

export const getCollaboradorsApi = (year = "working") => {
	return axiosService.get(
		"/temporada_detalls/" + year + "/?s=llistat_collaboradors"
	)
}

export const updateCollaboradorsApi = (collaboradorsRows, year = "working") => {
	return axiosService.post(
		"/temporada_detalls/" + year + "/?s=llistat_collaboradors", collaboradorsRows
	)
}
