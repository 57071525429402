import React, {useEffect, useState} from "react";
import Body from "../../components/Layout/Body/Body";
import Button from "@material-ui/core/Button";
import EditableTable from "../../components/composed/EditableTable/EditableTableComponent/EditableTable";
import {faseRegularColumns} from "./faseRegularTableContent";
import {cleanRow} from "../../utils/utils";
import {Modal} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import {getFasesRegularsService} from "../../data/services/FasesRegulars/FasesRegularsService";

const FasesRegularsScreen = ({history}) => {

	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);
	const [addedActions, setAddedActions] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const [isLoading, setIsLoading] = useState(false);


	useEffect(() => {
		setIsLoading(true)
		getFasesRegularsService()
			.then(res => {
				console.log("[FasesRegularsScreen] res", res)
				setColumns(faseRegularColumns)
				setRows(res.rows)
				setIsLoading(false)
			})
			.catch(err => {
				console.error("[FasesRegularsScreen] error fetching rows", err)
				setIsLoading(false)
			})
	}, [])

	useEffect(() => {
		if (addedActions) return
		let newRows = [...rows];

		newRows.map(row => {
			row.actions = {openURL, seeResults}
			row.validated.action = openModal
		});
		setRows(newRows);
		setAddedActions(true);
	}, [rows])

	const openModal = () => {
		setModalOpen(true)
	};

	const handleModalClose = () => {
		setModalOpen(false);
	}

	const openURL = (e) => {
		e.stopPropagation();
	};

	const seeResults = (e) => {
		e.stopPropagation();
	};

	const handleRowColor = row => {

	};

	const handleCellClick = (cell) => {
		if (cell.field === 'actions') return
		if (cell.field === "validated") {
			if (cell.value.details === true) return;
			setModalContent(cell.value.details);
			openModal();
			return;
		}
		let eliminatoriaInfo = cell.row;
		console.info('Info de la fila seleccionada', eliminatoriaInfo);
		history.push({
			pathname: '/fases-regulars/detail/' + eliminatoriaInfo.id,
			state: eliminatoriaInfo
		});
	}

	return (
		<Body>
			<EditableTable
				getRowClassName={(params) => handleRowColor(params)}
				rows={rows}
				onCellClick={(cell) => handleCellClick(cell)}
				columns={columns}
				editable={false}
				deletable={false}
				insertable={false}
				editedRows={(editedRows) => {
					setRows(editedRows)
				}}
				addNewRow={cleanRow}
				isLoading={isLoading}
			/>

			<p>Notes informatives:</p>
			<ul>
				<li>En cas d&rsquo;error de validaci&oacute;, l&rsquo;eliminat&ograve;ria que tingui l&rsquo;error es
					mostrar&agrave; a la columna. Fer clic a sobre pre veure els detalls de validaci&oacute; per saber
					què ha fallat.
				</li>
				<li>En qualsevol altre cas, la fila de la seg&uuml;ent eliminat&ograve;ria a partir de la data actual es
					mostrar&agrave; amb un fons blau
				</li>
			</ul>
			<Modal
				open={modalOpen}
				onClick={handleModalClose}
				onClose={handleModalClose}
			>

				<Fade in={modalOpen}>
					<div style={{
						display: "flex",
						flex: 1,
						height: "100vh",
						width: "100vw",
						alignItems: "center",
						justifyContent: "center"
					}}>
						<Paper elevation={3} style={{display: "flex", flexDirection: "column", padding: 35}}>
							<h2>Eliminatòria incompleta</h2>
							<div dangerouslySetInnerHTML={{__html: modalContent}}/>
							<p>Per tornar a intentar la validació, només cal refrescar la pàgina.</p>
							<Button style={{width: 200, alignSelf: 'center', margin: 20}} variant={'contained'}
									color={'primary'} onClick={handleModalClose}>Tancar</Button>
						</Paper>
					</div>
				</Fade>
			</Modal>

		</Body>
	)
};

export default FasesRegularsScreen
