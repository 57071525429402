import moment from "moment";

export const calendariSardanesFormatterFromApi = ([calendar, sardanes], eliminatories) => {
	console.log("data calendariSardanesFromAPI", [calendar, sardanes])
	let sardanesAvailable = sardanesFormatterFromApiToMultiSelect(sardanes.rows);

	let calendarRows = calendar.rows
	let combinedRows = []

	calendarRows.map((calRow) => {
		let sardanesSelected = []
		calRow.sardanes.forEach((sard) => {
			sardanesSelected.push(
				{value: sard.idSardana, label: sard.Titol}
			)
		})
		combinedRows.push(
			{
				id: calRow.idElim,
				ordreEmissio: calRow.nOrdreEmissio,
				ordreResultats: calRow.nOrdreResultats,
				tipus: calRow.idTipus,
				nOrdreTipus: calRow.nOrdreTipus,
				sardanes: {
					selected: sardanesSelected,
					available: sardanesAvailable,
				},
				eliminatoriesValues: eliminatories,
				dataInici: moment(calRow.dInici).toDate(),
				dataFinal: moment(calRow.dFinal).toDate(),
			}
		)
	})
	combinedRows.sort((a, b) => a.ordreEmissio - b.ordreEmissio);
	return combinedRows
};

export const sardanesFormatterFromApiToMultiSelect = (sardanes) => {
	let res = [];
	sardanes.map((sard, index) => {
		res.push({value: sard.id, label: sard.titol})
	})
	return res;
}

export const updateCalendariSardanesFormatterToApi = (calendarRows) => {
	//transform rows...
	return calendarRows;
}

export const calendariTipusEliminatoriesFormatterFromApi = (tipusElim) => {
	let res = [];
	tipusElim.forEach(item => {
		res.push({
			value: item.id,
			label: item.titol
		})
	})
	return res;
}
