import {getUsersApi, updateUsersApi} from "../api/usersApi";
import {userFormatterToApi, usersFormatterFromApi} from "../formatters/usersFormatter";

export const getUsersService = () => {
	return new Promise((resolve, reject) => {
		getUsersApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(usersFormatterFromApi(responseData))
				} else {
					throw new Error("[usersService] error getting users rows data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
};

export const updateUsersService = (usersRows) => {

	let arrPromieses = [];

	usersRows.forEach(user => {
		const pro = new Promise((resolve, reject) => {
			updateUsersApi(userFormatterToApi(user))
				.then(res => {
					if (res.status === 200) {
						const responseData = res.data.res;
						if (responseData) resolve(usersFormatterFromApi(responseData))
					} else {
						throw new Error("[usersService] Update users rows error")
					}
				})
				.catch(error => {
					console.error(error)
					reject(error.toString())
				})
		})
		arrPromieses.push(pro);
	})

	return Promise.all(arrPromieses)
		.then(() => {
			return true
		})
		.catch(err => {
			console.error(err)
			throw err;
		})

}
