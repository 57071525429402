import {updateSardanesClassificadesApi} from "../../api/editarTemporada/sardanesClassificadesApi";
import {sardanesClassificadesFormatterToApi} from "../../formatters/editarTemporada/sardanesClassificadesFormatter";
import {getSardanesClassificadesFormatter} from "../../formatters/concertsFormatter";

export const updateSardanesClassificadesService = (sardanesClassificadesRows) => {
	return new Promise((resolve, reject) => {
		updateSardanesClassificadesApi(sardanesClassificadesFormatterToApi(sardanesClassificadesRows))
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(getSardanesClassificadesFormatter(responseData))
				} else {
					throw new Error("[updateSardanesClassificadesService] Update sardanes classificades rows error")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}
