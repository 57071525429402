import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import XLS from "../../../assets/xls.png"
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import ModalEditSardana from "../Modals/ModalEditSardana";
import {
	getResultatsConcertsService,
	updateResultatsConcertsService
} from "../../../data/services/resultatsConcertsService";
import {CONCERT_PREMIS_TYPES, POSICIONS_ORDRE_SARDANES, RESULTS_TABLE_TYPES} from "../../../utils/constants";
import ModalAddSardana from "../Modals/ModalAddSardana";
import {Box, Snackbar, TextField} from "@material-ui/core";
import Alert from "../../unit/Alert/Alert";
import {getSardanesService} from "../../../data/services/editarTemporada/sardanesService";
import {getConcertsService} from "../../../data/services/concertsService";

//row to send to the API
let editedRowsResultatsPremiPopular = [];
let editedRowsResultatsPremiCritica = [];
let editedRowsResultatsPremiJoventut = [];


const ResultsConcert = (props, ref) => {

	const {setNumResults} = props
	const [resultatsPremiPopularRows, setResultatsPremiPopularRows] = useState([]);
	const [resultatsPremiCriticaRows, setResultatsPremiCriticaRows] = useState([]);
	const [resultatsPremiJoventutRows, setResultatsPremiJoventutRows] = useState([]);
	const [numberNullVotation, setNumberNullVotation] = useState(0);
	const [totalNumberVotation, setTotalNumberVotation] = useState(0);
	const [sardanesClassificadesRows, setSardanesClassificadesRows] = useState([]);
	const [jurat, setJurat] = useState("");

	const [editedVots, setEditedVots] = useState(false);

	//Modal d'edició de sardana
	const [currentIndex, setCurrentIndex] = useState(0);
	const [openModalEditSardana, setOpenModalEditSardana] = useState(false);
	const [editingSardana, setEditingSardana] = useState({});
	const [currentEditingTable, setCurrentEditingTable] = useState(null);

	//snackBars
	const [snackSuccess, setSnackSuccess] = useState(false)
	const [snackSuccessMsg, setSnackSuccessMsg] = useState("")
	const [snackError, setSnackError] = useState(false)
	const [snackErrorMsg, setSnackErrorMsg] = useState("")


	//Modal d'afegir sardana
	const [openModalAddSardana, setOpenModalAddSardana] = useState(false);

	useEffect(() => {
		loadAll();
	}, [])

	useImperativeHandle(ref, () => ({
		updateAll,
		loadAll
	}));


	const loadAll = () => {

		getResultatsConcertsService(CONCERT_PREMIS_TYPES.RESULTATS_PREMI_POPULAR)
			.then(({res, infoVots}) => {
				setResultatsPremiPopularRows(res)
				let totalValidVotes = 0;
				res.forEach(row => {
					totalValidVotes += parseInt(row.votes)
				})
				setNumResults(parseInt(infoVots.totalVotes) ? parseInt(infoVots.totalVotes) : totalValidVotes)
				setNumberNullVotation(parseInt(infoVots.nullVotes) ? parseInt(infoVots.nullVotes) : 0)
				setTotalNumberVotation(parseInt(infoVots.totalVotes) ? parseInt(infoVots.totalVotes) : 0)
			})
			.catch(error => {
				console.error(error)
			})

		getResultatsConcertsService(CONCERT_PREMIS_TYPES.RESULTATS_PREMI_CRITICA)
			.then(({res}) => {
				setResultatsPremiCriticaRows(res)
			})
			.catch(error => {
				console.error(error)
			})

		getResultatsConcertsService(CONCERT_PREMIS_TYPES.RESULTATS_PREMI_JOVENTUT)
			.then(({res}) => {
				setResultatsPremiJoventutRows(res)
			})
			.catch(error => {
				console.error(error)
			})

		getSardanesService()
			.then(res => {
				let formattedSardanes = res?.rows.map(sardana => {
					return {
						id: sardana.id,
						name: sardana.titol
					}
				})
				setSardanesClassificadesRows(formattedSardanes)
			})
			.catch(error => {
				console.error(error)
			})

		getConcertsService()
			.then(res => {
				setJurat(res.finalInfo.jurat)
			});

	}

	const getRowsFromTableType = (tableType) => {
		switch (tableType) {
			case RESULTS_TABLE_TYPES.TABLE_PREMI_POPULAR:
				return resultatsPremiPopularRows;
			case RESULTS_TABLE_TYPES.TABLE_PREMI_CRITICA:
				return resultatsPremiCriticaRows;
			case RESULTS_TABLE_TYPES.TABLE_PREMI_JOVENTUT:
				return resultatsPremiJoventutRows;
		}
	}

	const editSardana = (tableType, index) => {
		let rows = getRowsFromTableType(tableType)
		setCurrentEditingTable(tableType)
		setCurrentIndex(index)
		const s = rows[index]
		console.log("Editar Sardana - " + s.name)
		console.log(s)
		setEditingSardana(s)
		setOpenModalEditSardana(true)
	}

	const addSardana = (tableType) => {
		setCurrentEditingTable(tableType)
		setOpenModalAddSardana(true)
		console.log("ADD SARDANA FOR tabletype:", tableType)
	}

	const updateAll = (callback) => {
		console.log("UPDATE ALL");
		updateResultatsConcertsService({
			resultatsPremiPopularRows,
			resultatsPremiCriticaRows,
			resultatsPremiJoventutRows
		}, totalNumberVotation, numberNullVotation, jurat)
			.then((res) => {
				console.log("UPDATE ALL RESULT", res)
				setResultatsPremiPopularRows(res.sardanesPremiPopular)
				setResultatsPremiCriticaRows(res.sardanesPremiCritica)
				setResultatsPremiJoventutRows(res.sardanesPremiJoventut)
				setJurat(res.finalInfo.jurat);
				setTotalNumberVotation(parseInt(res.finalInfo.nButlletesTotals));
				setNumberNullVotation(res.finalInfo.nButlletesInvalides)
				callback()
				setSnackSuccessMsg("Dades desades correctament.")
				setSnackSuccess(true);
			})
			.catch(error => {
				setSnackErrorMsg("Error actualitzant dades de resultats. No s'han desat.")
				setSnackError(true);
				callback()
				console.log(error)
			})
	}

	const getAllRowsForCurrentEditingTable = () => {
		let resultRows = []
		console.log("CURRENT EDITING TABLE => ", currentEditingTable)
		switch (currentEditingTable) {
			case RESULTS_TABLE_TYPES.TABLE_PREMI_POPULAR:
				resultRows = resultatsPremiPopularRows;
				break;
			case RESULTS_TABLE_TYPES.TABLE_PREMI_CRITICA:
				resultRows = resultatsPremiCriticaRows;
				break;
			case RESULTS_TABLE_TYPES.TABLE_PREMI_JOVENTUT:
				resultRows = resultatsPremiJoventutRows;
				break;
		}

		return resultRows;
	}

	const onCLickSaveEditSardana = (ordre, sardana, vots, id) => {
		console.log("modal info");
		console.log(ordre);
		console.log(sardana);
		console.log(vots);
		console.log(id);

		const newSardanesRows = getRowsFromTableType(currentEditingTable);
		newSardanesRows[currentIndex].order = ordre
		newSardanesRows[currentIndex].name = sardana
		newSardanesRows[currentIndex].votes = vots
		newSardanesRows[currentIndex].id = id
		switch (currentEditingTable) {
			case RESULTS_TABLE_TYPES.TABLE_PREMI_POPULAR:
				setResultatsPremiPopularRows(newSardanesRows)
				editedRowsResultatsPremiPopular.push(newSardanesRows[currentIndex])
				break;
			case RESULTS_TABLE_TYPES.TABLE_PREMI_CRITICA:
				setResultatsPremiCriticaRows(newSardanesRows)
				editedRowsResultatsPremiCritica.push(newSardanesRows[currentIndex])
				break;
			case RESULTS_TABLE_TYPES.TABLE_PREMI_JOVENTUT:
				setResultatsPremiJoventutRows(newSardanesRows)
				editedRowsResultatsPremiJoventut.push(newSardanesRows[currentIndex])
				break;
		}

		setOpenModalEditSardana(false);
	}

	const onClickSaveAddSardana = (ordre, sardana) => {

		console.log("SAVE SARDANA")
		console.log(ordre)
		console.log(sardana)

		let sardanaObject = {
			order: ordre,
			name: sardana.name,
			id: sardana.id
		}
		const newSardanesRows = getRowsFromTableType(currentEditingTable);
		newSardanesRows.push(sardanaObject);
		switch (currentEditingTable) {
			case RESULTS_TABLE_TYPES.TABLE_PREMI_CRITICA:
				setResultatsPremiCriticaRows(newSardanesRows)
				editedRowsResultatsPremiCritica.push(sardanaObject)
				break;
			case RESULTS_TABLE_TYPES.TABLE_PREMI_JOVENTUT:
				setResultatsPremiJoventutRows(newSardanesRows)
				editedRowsResultatsPremiJoventut.push(sardanaObject)
				break;
		}

		setOpenModalAddSardana(false);
	}


	return (
		<>
			<div style={{display: "flex", flexDirection: "row", flex: 1, alignItems: "center"}}>
				<p style={{fontSize: 22, fontWeight: "bold", color: "grey"}}>
					Resultats - Premi popular
				</p>
				<button style={{backgroundColor: "transparent", border: "none", display: 'none'}}
						onClick={() => alert("Descarrega l'arxiu")}>
					<img src={XLS} alt="xls-file"
						 style={{height: 40, width: 40, objectFit: "contain", marginLeft: 20}}/>
				</button>
			</div>

			<TableContainer>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Nº</TableCell>
							<TableCell align="left">Nom sardana</TableCell>
							<TableCell align="left">Punts</TableCell>
							<TableCell align="left">Accions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{resultatsPremiPopularRows.map((row, index) => (
							<TableRow key={row.name}>
								<TableCell component="th" scope="row" style={{maxWidth: 200}}>
									{index + 1}
								</TableCell>
								<TableCell align="left">{row.name}</TableCell>
								<TableCell align="left">{row.votes}</TableCell>
								<TableCell align="left">
									<IconButton color="primary" aria-label="action"
												onClick={() => editSardana(RESULTS_TABLE_TYPES.TABLE_PREMI_POPULAR, index)}>
										<EditIcon/>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>


			<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					style={{display: "flex", flex: 1, marginTop: 20, gap: 10, marginBottom: 20}}
				>
					<TextField id="Butlletes_totals" label="Butlletes totals" variant="outlined" type={"number"}
							   onChange={(e) => {
								   setTotalNumberVotation(e.target.value)
								   setEditedVots(true);
							   }}
							   value={parseInt(totalNumberVotation)}
					/>
					<TextField id="Butlletes_nules" label="Butlletes nul·les" variant="outlined" type={"number"}
							   value={numberNullVotation}
							   onChange={(e) => {
								   setNumberNullVotation(e.target.value)
								   setEditedVots(true);
							   }}
					/>
					<TextField id="Butlletes_valides" label="Butlletes vàlides" variant="outlined" type={"number"}
							   disabled
							   value={parseInt(totalNumberVotation) - parseInt(numberNullVotation)}
					/>
					<div style={{flex: 1}}/>

					<TextField id="total_punts" label="Total de punts" variant="outlined" type={"number"}
							   disabled
							   value={resultatsPremiPopularRows?.map(row => row.votes).reduce((a, b) => parseInt(a) + parseInt(b), 0)}
					/>

				</Box>

				<button style={{
					border: "none",
					backgroundColor: "transparent",
					display: "none",
					flexDirection: "row",
					alignItems: "center"
				}}>
					<img src={XLS} alt="xls-no-filters"
						 style={{height: 40, width: 40, objectFit: "contain", marginLeft: 40}}/>
					<p style={{fontSize: 16, fontWeight: "bold", marginLeft: 10}}>Descarrega els punts sense filtres</p>
				</button>

				<button style={{
					border: "none",
					backgroundColor: "transparent",
					display: "none",
					flexDirection: "row",
					alignItems: "center"
				}}>
					<img src={XLS} alt="xls-users"
						 style={{height: 40, width: 40, objectFit: "contain", marginLeft: 40}}/>
					<p style={{fontSize: 16, fontWeight: "bold", marginLeft: 10}}>Descarrega els usuaris</p>
				</button>

			</div>

			<div style={{height: 2, width: "100%", backgroundColor: "lightgrey"}}/>

			<p style={{fontSize: 22, fontWeight: "bold", color: "grey"}}>
				Resultats - Premi de la crítica
			</p>

			<TableContainer>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Nº</TableCell>
							<TableCell align="left">Nom sardana</TableCell>
							<TableCell align="left">Accions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{resultatsPremiCriticaRows.map((row, index) => (
							<TableRow key={row.name}>
								<TableCell component="th" scope="row" style={{maxWidth: 200}}>
									{row.order}
								</TableCell>
								<TableCell align="left">{row.name}</TableCell>
								<TableCell align="left">
									<IconButton color="primary" aria-label="action"
												onClick={() => editSardana(RESULTS_TABLE_TYPES.TABLE_PREMI_CRITICA, index)}>
										<EditIcon/>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<div style={{
				display: resultatsPremiCriticaRows.length < 2 ? "flex" : 'none',
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "flex-end",
				marginTop: 20,
				marginBottom: 20
			}}>
				<Button variant="outlined" color="primary"
						onClick={() => addSardana(RESULTS_TABLE_TYPES.TABLE_PREMI_CRITICA)}>
					Afegir sardana premi de la crítica
				</Button>
			</div>

			<div style={{height: 2, width: "100%", backgroundColor: "lightgrey"}}/>

			<p style={{fontSize: 22, fontWeight: "bold", color: "grey"}}>
				Resultats - Premi Joventut
			</p>

			<TableContainer>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Nº</TableCell>
							<TableCell align="left">Nom sardana</TableCell>
							<TableCell align="left">Accions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{resultatsPremiJoventutRows.map((row, index) => (
							<TableRow key={row.name}>
								<TableCell component="th" scope="row" style={{maxWidth: 200}}>
									{row.order}
								</TableCell>
								<TableCell align="left">{row.name}</TableCell>
								<TableCell align="left">
									<IconButton color="primary" aria-label="action"
												onClick={() => editSardana(RESULTS_TABLE_TYPES.TABLE_PREMI_JOVENTUT, index)}>
										<EditIcon/>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<div style={{
				display: resultatsPremiJoventutRows.length < 2 ? "flex" : 'none',
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "flex-end",
				marginTop: 20,
				marginBottom: 20
			}}>
				<Button variant="outlined" color="primary"
						onClick={() => addSardana(RESULTS_TABLE_TYPES.TABLE_PREMI_JOVENTUT)}>
					Afegir sardana premi joventut
				</Button>
			</div>

			<div style={{marginTop: 20, marginBottom: 20, height: 2, width: "100%", backgroundColor: "lightgrey"}}/>

			<TextField id="jurat" label="Jurat" variant="outlined" multiline value={jurat} type={"text"}
					   rows={4}
					   onChange={(e) => setJurat(e.target.value)}/>


			<ModalEditSardana onClickDelete={() => null}
							  orderSardanaEditable={currentEditingTable !== RESULTS_TABLE_TYPES.TABLE_PREMI_POPULAR}
							  onClickSave={(ordre, sardana, vots, id) => onCLickSaveEditSardana(ordre, sardana, vots, id)}
							  open={openModalEditSardana}
							  sardanes={sardanesClassificadesRows}
							  handleOnCloseModal={() => setOpenModalEditSardana(false)}
							  currentOrdre={editingSardana.order}
							  currentSardana={editingSardana.name} currentVots={editingSardana.votes}
			/>

			<ModalAddSardana open={openModalAddSardana}
							 onClickSave={(ordre, sardana) => onClickSaveAddSardana(ordre, sardana)}
							 posicions={[POSICIONS_ORDRE_SARDANES.GUANYADORA, POSICIONS_ORDRE_SARDANES.ACCESSIT]}
							 tableType={currentEditingTable}
							 sardanes={sardanesClassificadesRows}
							 onClickDelete={() => null} handleOnCloseModal={() => setOpenModalAddSardana(false)}


			/>

			<Snackbar open={snackSuccess} autoHideDuration={6000} onClose={() => setSnackSuccess(false)}
					  anchorOrigin={{vertical: "top", horizontal: "right"}}>
				<Alert onClose={() => setSnackSuccess(false)} severity="success">{snackSuccessMsg}</Alert>
			</Snackbar>

			<Snackbar open={snackError} autoHideDuration={6000} onClose={() => setSnackError(false)}
					  anchorOrigin={{vertical: "top", horizontal: "right"}}>
				<Alert onClose={() => setSnackError(false)} severity="error">{snackErrorMsg}</Alert>
			</Snackbar>

		</>
	)
};

export default forwardRef(ResultsConcert);
