import {getCalendariApi, updateCalendariApi} from "../api/calendariApi";
import {calendariFormatterFromApi, updateCalendariFormatterToApi} from "../formatters/calendariFormatter";
import {getSardanesApi} from "../api/editarTemporada/sardanesApi";
import {sardanesFormatterFromApi} from "../formatters/editarTemporada/sardanesFormatter";
import {
	calendariSardanesFormatterFromApi,
	calendariTipusEliminatoriesFormatterFromApi,
	sardanesFormatterFromApiToMultiSelect
} from "../formatters/editarTemporada/calendariSardanesFormatter";
import {getTipusEliminatoriesService} from "./editarTemporada/tipusEliminatoriesService";


export const getCalendariService = () => {

	//TODO how to fer les 2 crides aqui, calendari i sardanes(sardanesApi ja existent)

	const promiseCalendari = new Promise((resolve, reject) => {
		getCalendariApi()
			.then(res => {
				console.log("promiseCalendari")
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(calendariFormatterFromApi(responseData))
				} else {
					throw new Error("[CalendariService] error getting calendari rows data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})

	const promiseEliminatories = getTipusEliminatoriesService();


	const promiseSardanesAvailable = new Promise((resolve, reject) => {
		getSardanesApi()
			.then(res => {
				console.log("promiseSardanesAvailable")
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(sardanesFormatterFromApi(responseData))
				} else {
					throw new Error("[CalendariService] error getting sardanes rows data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})

	//TODO: here we need a new formatter to merge 2 api calls info

	return Promise.all([promiseCalendari, promiseSardanesAvailable, promiseEliminatories])
		.then(resValues => {
			let eliminatories = calendariTipusEliminatoriesFormatterFromApi(resValues[2].rows);
			let merge = calendariSardanesFormatterFromApi(resValues, eliminatories)
			return {rows: merge, sardanes: sardanesFormatterFromApiToMultiSelect(resValues[1].rows), eliminatories}

		})
		.catch(error => {
			console.log(error)
		})

}

export const updateCalendariService = (calendariRows) => {
	return new Promise((resolve, reject) => {
		updateCalendariApi(updateCalendariFormatterToApi(calendariRows))
			.then(async res => {
				if (res.status === 200) {
					let data = await getCalendariService();
					if (data) resolve(data)
				} else {
					throw new Error("Update calendari rows")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}


