export const collaboradorsColumns = [
	{field: "id", headerName: "ID", hide: true},
	{
		field: "nom",
		width: 240,
		headerName: "Nom",
		type: "string",
	},
	{
		field: "email",
		width: 300,
		headerName: "Correu electrònic",
		type: "string",
	},
];

export const collaboradorsRows = [
	{
		id: 1,
		nom: "Pepet",
		width: 180,
		email: "pepet@gmail.com",

	},

];
