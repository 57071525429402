export const getResultatsConcertsFormatter = (response) => {
	let sardanes = []
	response.forEach(sardana => {
		sardanes.push({
			id: sardana.id_sardana,
			name: sardana.nom_sardana,
			order: sardana.ordre,
			votes: sardana.num_vots,
		})
	})
	return sardanes;
}

export const getInfoVotsFormatter = (response) => {
	return {
		validVotes: response.vots_valids,
		totalVotes: response.vots_totals,
		nullVotes: response.vots_nuls
	}
}


export const updateResultatsConcertsFormatter = (requestData) => {
	let sardanesPremiPopular = []
	let sardanesPremiCritica = []
	let sardanesPremiJoventut = []
	requestData.resultatsPremiPopularRows.forEach(sardana => {
		sardanesPremiPopular.push({
			id_sardana: sardana.id,
			nom_sardana: sardana.name,
			ordre: sardana.order,
			num_vots: sardana.votes,
		})
	})
	requestData.resultatsPremiCriticaRows.forEach(sardana => {
		sardanesPremiCritica.push({
			id_sardana: sardana.id,
			ordre: sardana.order,
		})
	})
	requestData.resultatsPremiJoventutRows.forEach(sardana => {
		sardanesPremiJoventut.push({
			id_sardana: sardana.id,
			ordre: sardana.order,
		})
	})
	return {sardanesPremiPopular, sardanesPremiCritica, sardanesPremiJoventut};
}
