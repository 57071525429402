import axiosService from "../services/axiosService";

export const authenticateApi = (username, password) => {
	return axiosService.post(
		"/auth/login",
		{
			username,
			password
		}
	)
}

export const requestNewPasswordApi = (username) => {
	return axiosService.post(
		"/auth/recoverPassword",
		{
			username
		}
	)
}

export const setNewPasswordApi = (token, password1, password2) => {
	return axiosService.post(
		"/auth/recoverPassword",
		{
			token,
			password1,
			password2
		}
	)
}

export const logoutAPI = () => {
	return axiosService.post(
		"/auth/logout",
		{}
	)
}
