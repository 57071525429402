import {getEmissoresApi, updateEmissoresApi} from "../../api/editarTemporada/emissoresApi";
import {emissoresFormatterFromApi, emissoresFormatterToApi} from "../../formatters/editarTemporada/emissoresFormatter";

export const getEmissoresService = () => {
	return new Promise((resolve, reject) => {
		getEmissoresApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(emissoresFormatterFromApi(responseData))
				} else {
					throw new Error("[emissoresService] error getting emissores rows data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
};

export const updateEmissoresService = (emissoresRows) => {
	return new Promise((resolve, reject) => {
		updateEmissoresApi(emissoresFormatterToApi(emissoresRows))
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(emissoresFormatterFromApi(responseData))
				} else {
					throw new Error("[emissoresService] Update emissores rows error")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}
