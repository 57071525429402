export const temporadaFormatterFromApi = (data) => {
	return {
		temporades: [2017, 2018, 2019, 2020, 2021],
	}
};


export const temporadesFormatterFromApi = (data) => {
	let temporades = [];
	data?.res.forEach((item) => {
		temporades.push({id: item.id, any: item.any, name: "Temporada " + item.any, active: item.active_temporada})
	})
	return {temporades}

};

export const createNovaTemporadaFormatterToApi = (year) => {
	//transform year...
	return year.toString();
}
