import * as React from "react";
import {useEffect, useState} from "react";
import {DataGrid} from "@material-ui/data-grid";
import cloneDeep from "lodash/cloneDeep";
import DeleteButton from "../Units/DeleteButton";
import {Button, makeStyles} from "@material-ui/core";
import MultipleSelect from "../Units/MultipleSelect";
import style from "./style";
import * as PropTypes from "prop-types";

const checkEditable = (tableColumns, editable) => {
	if (!editable) return tableColumns;
	return tableColumns.map((col) => {
		if (col.editable === false) {
			return col;
		}
		if (!col.protected) {
			return {
				...col,
				editable: col.field !== "id",
			}
		} else {
			return {
				...col,
				editable: false
			}
		}
	});
};

class GridActionsCellItem extends React.Component {
	render() {
		return null;
	}
}

GridActionsCellItem.propTypes = {
	icon: PropTypes.any,
	onClick: PropTypes.any,
	label: PropTypes.string
};
const checkDeletableCols = (tableColumns, deletable, handleDeleteRow) => {
	if (!deletable) return tableColumns;
	const deleteColumn = {
		field: "Esborrar_fila",
		disableClickEventBubbling: true,
		width: 40,
		align: 'center',
		type: "",
		renderCell: (params) => (
			<DeleteButton params={params} onClick={handleDeleteRow}/>
		),
	};

	return [deleteColumn].concat(tableColumns);
};

const checkMultipleSelect = (tableColumns, setTableRows, onEdited) => {
	let cols = [];
	for (let i = 0; i < tableColumns.length; i++) {
		const col = tableColumns[i];
		if (
			col.hasOwnProperty("customType") &&
			col?.customType === "multipleSelect"
		) {
			col["width"] = 240;
			col["renderCell"] = (params) => (
				<MultipleSelect params={params} setTableRows={setTableRows} onEdited={onEdited}/>
			);
			col["editable"] = false;
		}
		cols.push(col);
	}
	return cols;
};

const useStyles = makeStyles({
	root: {
		"& .orange": {
			backgroundColor: "#fbf0ab",
		},
		"& .blue": {
			backgroundColor: "#e8f5ff",
		},
	},
});


const EditableTable = ({
						   rows,
						   columns,
						   editable,
						   deletable,
						   insertable,
						   showTotals = true,
						   onRowAdded,
						   onRowDeleted,
						   addNewRow,
						   emptyRow = {},
						   onRowClick = null,
						   title,
						   addButtonTitle,
						   onEditedTable,
						   isLoading = false,
						   getRowClassName,
						   onCellClick = null,
					   }) => {
	const [tableRows, setTableRows] = useState(rows);
	const classes = useStyles();

	useEffect(() => {
		setTableRows(rows)

	}, [rows])

	let tableColumns = cloneDeep(columns);

	// Edit
	tableColumns = checkEditable(tableColumns, editable);

	const handleDeleteRow = (row) => {
		const res = window.confirm("Segur que vols esborrar aquesta fila?");
		if (!res) return;
		let index = tableRows.findIndex(r => r.id === row.id)
		let newTableRows = [...tableRows]
		newTableRows.splice(index, 1)
		setTableRows(newTableRows)
		onEditedTable(newTableRows, row.id)
	}

	// Delete
	tableColumns = checkDeletableCols(tableColumns, deletable, handleDeleteRow);

	// Add
	const handleAdd = () => {
		if (!isLoading) {
			const lastRow = tableRows.length >= 1 ? tableRows[rows.length - 1] : null;
			let id = rows.length === 0 ? '1' : "";
			if (lastRow) {
				id = parseInt(lastRow?.id) + 1;
			}
			let newTableRows = [...tableRows, addNewRow(emptyRow, id)]
			setTableRows(newTableRows);
			onRowAdded(true)
			onEditedTable(newTableRows, newTableRows.length)
		}
	};

	const handleMultiSelectEdit = (rowId, field, selected) => {
		let index = tableRows.findIndex(r => r.id === rowId)
		tableRows[index][field].selected = selected
		setTableRows([...tableRows])
		onEditedTable(tableRows, rowId)
	}

	// Multiple Select
	tableColumns = checkMultipleSelect(tableColumns, setTableRows, handleMultiSelectEdit);

	// Edit row
	const handleRowEdited = (row) => {
		let index = tableRows.findIndex(r => r.id === row.id)
		tableRows[index][row.field] = row.value
		setTableRows([...tableRows])
		onEditedTable(tableRows, row.id)
	}

	return (
		<div style={style.container} className={classes.root}>
			<div style={style.tableHeader}>
				<p style={style.tableTitle}>{title}</p>
			</div>
			<DataGrid
				getRowClassName={getRowClassName}
				rows={tableRows}
				columns={tableColumns}
				pageSize={100}
				onRowClick={onRowClick}
				onCellClick={onCellClick}
				autoHeight={true}
				hideFooter
				loading={isLoading}
				onCellEditCommit={(row) => {
					handleRowEdited(row)
				}}
			/>
			<div style={style.tableFooter}>
				{showTotals ? (
					<div style={style.tableTotalRows}>
						Total {tableRows.length}
					</div>
				) : null}
				{insertable ? (
					<Button
						style={{marginTop: "1rem", marginLeft: "auto"}}
						color={"secondary"}
						variant={"outlined"}
						onClick={handleAdd}
					>
						Afegir fila
					</Button>
				) : null}
			</div>
		</div>
	)
};

export default React.memo(EditableTable);
