import axiosService from "../services/axiosService";

export const getUsersApi = () => {
	return axiosService.get(
		"/users/"
	)
}

export const updateUsersApi = (user) => {
	console.log(user)
	return axiosService.post(
		"/users/" + user.id,
		{
			username: user.username,
			email: user.email,
			status: user.status,
			is_admin: user.is_admin,
		}
	)
}
