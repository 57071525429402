import {getDadesTemporadaApi, updateDadesTemporadaApi} from "../../api/editarTemporada/dadesTemporadaApi";
import {
	dadesTemporadaFormatterFromApi,
	dadesTemporadaFormatterToApi
} from "../../formatters/editarTemporada/dadesTemporadaFormatter";

export const getDadesTemporadaService = () => {
	return new Promise((resolve, reject) => {
		getDadesTemporadaApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(dadesTemporadaFormatterFromApi(responseData))
				} else {
					throw new Error("[DedesTemporadaService] Error getting DedesTemporada data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
};

export const updateDadesTemporadaService = (tipusEliminatoriesRows) => {
	return new Promise((resolve, reject) => {
		updateDadesTemporadaApi(dadesTemporadaFormatterToApi(tipusEliminatoriesRows))
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(dadesTemporadaFormatterFromApi(responseData))
				} else {
					throw new Error("[DedesTemporadaService] Error update DedesTemporada")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}
