import {
	actionsVoteAPI,
	getConcertsApi,
	getPassKeywords,
	getSardanesClassificades,
	updateConcersApi
} from "../api/concertsAPI";
import {
	actionsVoteFormatterToApi,
	concertsFormatter,
	getPassKeywordsFormatter,
	getSardanesClassificadesFormatter,
	updateConcertFormatterToApi
} from "../formatters/concertsFormatter";

export const getConcertsService = () => {
	return new Promise((resolve, reject) => {
		getConcertsApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(concertsFormatter(responseData))
				} else {
					throw new Error("Getting data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
};

export const updateConcertsService = (keyword) => {
	return new Promise((resolve, reject) => {
		updateConcersApi(updateConcertFormatterToApi({keyword}))
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(concertsFormatter(responseData))
				} else {
					throw new Error("Update concerts")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}

export const getPassKeywordsService = () => {
	return new Promise((resolve, reject) => {
		getPassKeywords()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(getPassKeywordsFormatter(responseData))
				} else {
					throw new Error("Getting data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}

export const getSardanesClassificadesService = () => {
	return new Promise((resolve, reject) => {
		getSardanesClassificades()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(getSardanesClassificadesFormatter(responseData))
				} else {
					throw new Error("Getting data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}

export const actionsVoteService = (type) => {
	return new Promise((resolve, reject) => {
		actionsVoteAPI(actionsVoteFormatterToApi(type))
			.then(res => {
				if (res.status === 200) {
					resolve();
					console.log("actionsVoteService - correct")
				} else {
					throw new Error("Action Error")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error)
			})
	})
}
