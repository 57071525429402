import axiosService from "../../services/axiosService";

export const getTemporadaApi = (year = "working") => {
	/*return new Promise((resolve, reject) => {
		resolve({status: 200, data: true})
	})*/
	return axiosService.get(
		"/temporada/" + year
	)
}

export const getTemporadesApi = () => {
	return axiosService.get(
		"/temporada"
	)
}

export const createNovaTemporadaApi = (year) => {
	return axiosService.post(
		"/temporada", {"any": year}
	)
}

export const setTemporadaTreballApi = (temporadaId) => {
	return axiosService.post(
		"/temporada/treball", {temporadaId}
	)
}
