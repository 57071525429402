import React, {useEffect, useState} from 'react';
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import {FormControl, InputLabel, MenuItem, Modal} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import {RESULTS_TABLE_TYPES} from "../../../utils/constants";


const ModalAddSardana = ({
							 onClickSave, onClickDelete, tableType,
							 posicions = [],
							 sardanes = [], open, handleOnCloseModal
						 }) => {


	const [posicio, setPosicio] = useState("");
	const [sardana, setSardana] = useState("");
	const [sardanesList, setSardanesList] = useState(sardanes)

	useEffect(() => {
		setSardanesList(sardanes)
	}, [sardanes])


	const submitForm = () => {
		let sardanaObj = sardanes.find(sard => sard.id === sardana);
		onClickSave(posicio, sardanaObj);
		setSardana("");
		setPosicio("");
	}

	const handlePosicioChange = (e) => {
		setPosicio(e.target.value)
	}

	const handleSardanaChange = (e) => {
		setSardana(e.target.value)
	}


	return (

		<Modal open={open} onClose={handleOnCloseModal}>

			<Fade in={open}>
				<div style={{
					display: "flex",
					flex: 1,
					height: "100vh",
					width: "100vw",
					alignItems: "center",
					justifyContent: "center"
				}}>
					<Paper elevation={3}
						   style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 40}}>

						<h2> {tableType === RESULTS_TABLE_TYPES.TABLE_PREMI_CRITICA ? "Afegir sardana premi de la crítica"
							: tableType === RESULTS_TABLE_TYPES.TABLE_PREMI_JOVENTUT ? "Afegir sardana premi de la crítica"
								: "Afegir sardana premi"}
						</h2>

						<form onSubmit={(event) => {
							event.preventDefault();
							submitForm()
						}}>

							<FormControl variant="outlined">
								<InputLabel>Posició</InputLabel>
								<Select variant={"outlined"} label="Posició" id="select-posicio" value={posicio}
										onChange={handlePosicioChange} style={{minWidth: 100}}
								>

									{posicions.map((pos) =>
										<MenuItem value={pos.order}>{pos.name}</MenuItem>
									)}
								</Select>
							</FormControl>


							<br/>
							<FormControl variant="outlined" style={{marginTop: 30}}>
								<InputLabel>Sardana</InputLabel>
								<Select
									style={{minWidth: 300}}
									variant={"outlined"}
									label="Sardana"
									id="select-sardana"
									value={sardana}
									onChange={handleSardanaChange}
								>

									{sardanesList.map((sardana) =>

										<MenuItem value={sardana.id}>{sardana.name}</MenuItem>
									)}
								</Select>
							</FormControl>


							<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>

								<Button style={{display: "flex", marginTop: 35, marginLeft: 200}}
										variant="outlined" onClick={handleOnCloseModal}>
									Cancel·lar
								</Button>

								<Button style={{display: "flex", marginTop: 35, marginLeft: 8}}
										variant="contained" color="primary" type="submit">
									Desar
								</Button>
							</div>

						</form>

					</Paper>
				</div>
			</Fade>
		</Modal>

	);

};

export default ModalAddSardana;
