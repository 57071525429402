import React from "react";
import Header from "../../composed/Header/Header";
import {Paper} from "@material-ui/core";
import {getFromStorage} from "../../../utils/utils";
import {USERNAME} from "../../../utils/constants";


const Body = ({children}) => {
	let username = getFromStorage(USERNAME);
	return (
		<div style={{width: "100%"}}>
			<Header userName={username}/>
			<Paper style={{margin: 32, padding: 32}}>
				{children}
			</Paper>
		</div>
	);
}

export default Body;
