export const sardanesFormatterFromApi = (data) => {

	let fromApiRows = []
	data.map((sardana) => {
		fromApiRows.push(
			{
				id: sardana.idSardana,
				titol: sardana.Titol,
				autor: sardana.Autor,
				cobla: sardana.Cobla,
				audio: sardana.mp3Curt,
			}
		)
	})
	return {

		rows: fromApiRows,
	}
};

export const sardanesFormatterToApi = (sardanesRows) => {

	let forApiRows = []
	let lastId = 0;
	sardanesRows.map((row, index) => {
		forApiRows.push(
			{
				idSardana: row.id ? row.id : parseInt(lastId) + 1,
				Titol: row.titol,
				Autor: row.autor,
				Cobla: row.cobla,
				mp3Curt: row.audio ? row.audio : "",
				idBBDD: '0',
			}
		)
		lastId = row.id ? row.id : parseInt(lastId) + 1;

	})
	console.info("forApiRows", forApiRows)
	return forApiRows
};
