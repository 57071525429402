import axiosService from "../../services/axiosService";

export const getSardanesApi = (year = "working") => {

	/*let d = [
		{
			"idSardana": "1",
			"Titol": "Essència d'aplec",
			"Autor": "Enric Ortí",
			"Cobla": "Sant Jordi",
			"mp3-curt": ""working"-ELIM01-1aSARD"
		},

	]

	return new Promise((resolve, reject) => {
		resolve({status: 200, data: d})
	})*/

	return axiosService.get(
		"/temporada_detalls/" + year + "/?s=sardanes"
	)
}

export const updateSardanesApi = (sardanesRows, year = "working") => {
	console.log("sardanesRows", sardanesRows)

	/*
	return new Promise((resolve, reject) => {
		resolve({status: 201, data: sardanesRows})
	})*/

	return axiosService.post(
		"/temporada_detalls/" + year + "/?s=sardanes", sardanesRows
	)

}
