//2 types of object to represent an Audio file:
/*
* Domain audiofile has id, filename, and audioURL.
 */

export const audioFilesFormatterFromApi = (data) => {
	let audioFiles = []
	data.res.forEach(audioFile => {
		audioFiles.push(
			{
				id: audioFile.idAudio,
				filename: audioFile.nomAudio,
				eliminatoria: audioFile.idElimAssociada,
				asociatedSardanaName: audioFile.Titol,
				audioURL: audioFile.pathExtern,
			}
		)
	})

	return audioFiles
};

export const audioFilesFormatterToApi = (rows) => {
	let audioFiles = [];
	rows.forEach(audioFile => {
		audioFiles.push(
			{
				idAudio: audioFile.id,
				nomAudio: audioFile.filename
			}
		)
	})

	return audioFiles
}
