import {makeStyles, Modal, Tooltip} from "@material-ui/core";
import React, {useState} from "react";
import Select from "react-select";
import Chip from "@material-ui/core/Chip";

const customStyles = {
	control: (provided) => ({
		// none of react-select's styles are passed to <Control />
		...provided,
		lineHeight: "1.5em",
		width: "100%",
	}),
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
	},
	modal: {
		display: "flex",
		padding: theme.spacing(1),
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		width: 500,
		maxHeight: 300,
		overflow: "inherit",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));

function MultipleSelect({params, setTableRows, onEdited}) {
	const [selected, setSelected] = useState(params.value.selected);
	const [open, setOpen] = React.useState(false);
	const classes = useStyles();
	const rootRef = React.useRef(null);

	const {maxSelect} = params.colDef;

	const handleChange = (e) => {
		if (e.length > maxSelect) {
			e.pop();
			setSelected(e);
			onEdited(params.id, params.field, e)
		}
		else {
			setSelected(e);
			onEdited(params.id, params.field, e)
		}
	};

	return (
		<>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				className={classes.modal}
				container={() => rootRef.current}
			>
				<div className={classes.paper}>
					<Select
						styles={customStyles}
						defaultValue={selected}
						options={selected.length >= maxSelect ? [] : params.value.available}
						name="Sardanes"
						noOptionsMessage={() => {
							return selected.length === maxSelect ? 'Ja has seleccionat les ' + maxSelect + ' opcions possibles.' : "No hi ha opcions. Insereix sardanes i desa-les.";
						}}
						isMulti
						onChange={(e) => handleChange(e)}
					/>
				</div>
			</Modal>
			<div
				style={{
					width: "100%",
					display: "flex",
					height: 52,
					alignContent: "center",
					overflowX: "auto",
					overflowY: "hidden"
				}}
				onClick={() => setOpen(true)}
			>
				{selected &&
				selected.map((el) => (
					<Tooltip
						title={selected.map((el, ind) => (

							<p style={{fontSize: "13px", margin: 0}}>
								{ind + 1 + " - " + el.label}
							</p>
						))}
					>
						<Chip key={el.value} style={{margin: 4}} label={el.label}/>
					</Tooltip>

				))}
			</div>
		</>
	);
}

export default MultipleSelect;
