const findSardanaNomFromId = (sardanes, idSardana) => {
	let res = sardanes.find(el => el.idSardana === idSardana);
	return res ? res.Titol : "";
}

const findSardanaAutorFromId = (sardanes, idSardana) => {
	let res = sardanes.find(el => el.idSardana === idSardana);
	return res ? res.Autor : "";
}
export const faseRegularDetailFromatterFromAPI = (faseRegular) => {

	console.log(faseRegular)
	let res = [];
	let sardanes = ["idSardana1", "idSardana2", "idSardana3", "idSardana4", "idSardana5", "idSardana6"]

	let totalVots = 0;
	sardanes.forEach((sardana, index) => {
		let id = faseRegular['idSardana' + (index + 1)];
		if (id === '0') return;
		let item = {
			id: faseRegular['idSardana' + (index + 1)],
			numSardana: index + 1,
			sardana: findSardanaNomFromId(faseRegular.sardanes, id),
			autor: findSardanaAutorFromId(faseRegular.sardanes, id),
			resultats: faseRegular['nVotsSardana' + (index + 1)],
			vots: ""
		}
		totalVots += parseInt(faseRegular['nVotsSardana'+(index+1)]);
		res.push(item);
	})

	res.forEach(sardana => {
		sardana.vots = parseInt(sardana.resultats)!== 0 ? (parseInt(sardana.resultats)/totalVots * 100).toFixed(2) + " %" : "-"
	})

	return {
		faseRegular,
		rows: res,
		totalVots,
		votsEnBlanc: faseRegular.nVotsEnBlanc,
		votsNuls: faseRegular.nVotsNuls,
		txtAfegeixAResultat: faseRegular.txtAfegeixAResultat,
		txtSubstitueixResultat: faseRegular.txtSubstitueixResultat,
		publicat: faseRegular.publicat,
		pdfGeneratUrl: faseRegular.urlPdfGenerat
	}

}

const findVotsFromNumSardana = (data, num) => {
	for(let i = 0; i<data.length; i++) {
		const sardana = data[i];
		if(sardana.numSardana === num) {
			return sardana.resultats;
		}
	}
	return "0";
}
export const faseRegularDetailFromatterToAPI = (data, idElim) => {

	console.log("[faseRegularDetailFromatterToAPI]", data)

	const sardanes = data.sardanes;

	const res = {
		idElim,
		dInici: data.dInici,
		dFinal: data.dFinal,
		nVotsSardana1: findVotsFromNumSardana(sardanes, 1),
		nVotsSardana2: findVotsFromNumSardana(sardanes, 2),
		nVotsSardana3: findVotsFromNumSardana(sardanes, 3),
		nVotsSardana4: findVotsFromNumSardana(sardanes, 4),
		nVotsSardana5: findVotsFromNumSardana(sardanes, 5),
		nVotsSardana6: findVotsFromNumSardana(sardanes, 6),
		nVotsEnBlanc: data.votsEnBlanc,
		nVotsNuls: data.votsNuls,
		txtAfegeixAResultat: data.txtAfegeixAResultat,
		txtSubstitueixResultat: data.txtSubstitueixResultat
	}

	return res;

}
