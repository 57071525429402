import axiosService from "../../services/axiosService";

export const getDadesTemporadaApi = (year = "working") => {
	return axiosService.get(
		"/temporada_detalls/" + year + "?s=dades_temporada"
	)
}

export const updateDadesTemporadaApi = (dadesTemporadaRows, year = "working") => {
	return axiosService.post(
		"/temporada_detalls/" + year + "?s=dades_temporada", dadesTemporadaRows
	)
}

