export const collaboradorsFormatterFromApi = (data) => {
	let r = []
	data.map((emiss) => {
		console.log(emiss.correuCollaborador)
		r.push(
			{
				id: emiss.idCollaborador,
				nom: emiss.nomCollaborador,
				email: emiss.correuCollaborador,
			}
		)
	})

	return {

		rows: r,
	}
};

export const collaboradorsFormatterToApi = (emissoresRows) => {

	let forApiRows = []
	emissoresRows.map((row, index) => {
		forApiRows.push(
			{
				idCollaborador: index + 3,
				nomCollaborador: row.nom,
				correuCollaborador: row.email,
			}
		)
	})
	console.info("forApiRows", forApiRows)
	return forApiRows
};
