export const sardanesClassificadesFormatterToApi = (emissoresRows) => {

	let forApiRows = []
	emissoresRows.map((row, index) => {
		forApiRows.push(
			{
				idSardana: row.idSardana,
				nOrdre: index + 1,
				nVots: 0
			}
		)
	})
	console.info("forApiRows", forApiRows)
	return forApiRows
};
