import moment from "moment";

export const cleanRow = (row, id) => {
	let cleanRow = {...row};

	cleanRow["id"] = id;
	return cleanRow;
};

export const saveToStorage = (key, value) => {
	sessionStorage.setItem(key, value);
}

export const getFromStorage = (key) => {
	return sessionStorage.getItem(key);
}

/**
 * Returns the next {dayOfweek} from starterDate. If today is friday and we are asking for next friday the friday of the next week is returned.
 * @param starterDate format autoparsed by moment
 * @param dayOfWeek 0:Su,1:Mo,2:Tu,3:We,4:Th,5:Fr,6:Sa
 */


export const getNextFridayCalendar = (date) => {
	let dayOfTheWeek = moment(date).isoWeekday();
	let friday = 5;

	if (dayOfTheWeek < friday) {
		//Add .toDate() to return valid format date for the tables
		return moment(date).isoWeekday(friday);
	} else {
		return moment(date).add(1, "weeks").isoWeekday(friday);
	}
}

export const getNextMondayCalendar = (date) => {
	let dayOfTheWeek = moment(date).isoWeekday();
	let monday = 1;
	let friday = 5;

	if (dayOfTheWeek < friday) {
		return moment(date).add(1, "weeks").isoWeekday(monday);
	} else {
		return moment(date).add(2, "weeks").isoWeekday(monday);
	}
}
