import {createNovaTemporadaApi, getTemporadaApi, getTemporadesApi} from "../../api/temporades/temporadesApi";
import {
	createNovaTemporadaFormatterToApi,
	temporadaFormatterFromApi,
	temporadesFormatterFromApi
} from "../../formatters/novaTemporada/novaTemporadaFormatter";

export const getTemporadaService = () => {
	return new Promise((resolve, reject) => {
		getTemporadaApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(temporadaFormatterFromApi(responseData))
				} else {
					throw new Error("Getting temporades data error")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
};

export const getTemporadesService = () => {
	return new Promise((resolve, reject) => {
		getTemporadesApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(temporadesFormatterFromApi(responseData))
				} else {
					throw new Error("Getting temporades data error")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
};

export const createNovaTemporadaService = (year) => {
	return new Promise((resolve, reject) => {
		createNovaTemporadaApi(createNovaTemporadaFormatterToApi(year))
			.then(res => {
				console.log(res)
				//TODO: put 201 in the future
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(temporadaFormatterFromApi(responseData))
				} else {
					throw new Error("Create nova temporada error")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}
