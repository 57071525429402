
const findEliminatoriaLabelFromValue = (eliminatories, value) => {
	let res = eliminatories.find(el => el.value === value);
	return res ? res.label : "";
}
export const fasesRegularsEliminatoriesMerger = (calendari, eliminatories) => {

	let res = []
	calendari.forEach(faseRegRow => {
		res.push(
			{
				id: faseRegRow.idElim,
				ordre: faseRegRow.nOrdreEmissio,
				ordreEmissio: faseRegRow.nOrdreTipus,
				tipus: findEliminatoriaLabelFromValue(eliminatories, faseRegRow.idTipus),
				sardanes: faseRegRow.sardanes,
				dataInici: faseRegRow.dInici,
				dataFinal: faseRegRow.dFinal,
				published: faseRegRow.publicat,
				validated: {value: faseRegRow.validacio?.valid, details: faseRegRow.validacio?.details},
				simulacioURL: faseRegRow.simulacio
			}
		)
	})

	res.sort((a, b) => a.ordre - b.ordre);

	return res;
}

