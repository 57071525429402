import React, {useEffect, useState} from "react";
import BodyNewSeasonScreen from "../../components/composed/BodyNewSeason/BodyNewSeason";
import EditableTable from "../../components/composed/EditableTable/EditableTableComponent/EditableTable";
import {cleanRow} from "../../utils/utils";
import {Button, FormControlLabel, Snackbar, Switch} from "@material-ui/core";
import {dadesTemporadaColumns} from "./dadesTemporadaTableContent";
import {
	getDadesTemporadaService,
	updateDadesTemporadaService
} from "../../data/services/editarTemporada/dadesTemporadaService";
import Alert from "@material-ui/lab/Alert";
import {SNACKBAR_MUI_TYPES, SNACKBAR_STRINGS, SNACKBAR_TYPES} from "../../utils/constants";
import Prompt from "react-router-dom/es/Prompt";
import moment from "moment";

const emptyRow = {
	titol_principal: "",
	subtitol: "",
	any_lsda: "",
	supersardana: {
		value: false,
		onChange: null
	},
	data_inici: "",
	data_final_elim: "",
	data_inici_registre: "",
	data_final_registre: "",
	data_concert: "",
	url_final_tickets: "",
	url_final_streaming: "",
};

const DadesTemporadaScreen = () => {

	const [hasChanged, setHasChanged] = useState(false);
	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);

	const [autoSave, setAutoSave] = useState(true);
	const [lastSaved, setLastSaved] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("info");
	const [snackBarText, setSnackBarText] = useState("snackBar");

	const onChangeSwitchCell = (params, columnName) => {
		let newRows = [...rows]
		let rowIndex = newRows.findIndex(element => element.id === params.id);
		newRows[rowIndex][columnName].value = !newRows[rowIndex][columnName].value
		setRows(newRows)

	}

	useEffect(() => {
		setIsLoading(true)
		getDadesTemporadaService()
			.then(res => {
				setColumns(dadesTemporadaColumns)
				setRows(res.rows)
				console.log("rows", res.rows)
				setIsLoading(false)
			})
			.catch(err => {
				console.error("[DadesTemporadaScreen] error fetching rows", err)
				setIsLoading(false)
			})
	}, [])

	const saveTable = (silent = false, refreshContent = true, editedRows = null) => {
		//start loader
		if (!silent) setIsLoading(true)
		updateDadesTemporadaService(editedRows ? editedRows[0] : rows[0])
			.then(res => {
				if (refreshContent) {
					setRows(res.rows)
				}
				if (!silent) {
					setIsLoading(false)
					showSetupSnackBar(SNACKBAR_TYPES.SUCCESS)
				}
				setLastSaved("Últim desat a les " + moment().format("HH:mm:ss"))
			})
			.catch(err => {
				console.error("[dadesTemporadaScreen] error send rows", err)
				if (!silent) {
					showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
				}
				setIsLoading(false)
			})
		if (refreshContent) {
			setHasChanged(false)
		}
	};
	const showSetupSnackBar = (type) => {

		switch (type) {
			case SNACKBAR_TYPES.SUCCESS:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_GET_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_SEND_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_SEND_DATA)
				setShowSnackBar(true)
				break

			default:
				setSnackBarType("info")
				setSnackBarText("default switch")
				setShowSnackBar(true)

		}
	}

	const handleEditedRows = (editedRows) => {
		setRows(editedRows)
		setHasChanged(true)
		//console.info("[dadesTemporadaScreen] edited rows",editedRows)
		if (autoSave) saveTable(true, true, editedRows);
	}

	return (
		<BodyNewSeasonScreen>
			<Prompt
				when={hasChanged}
				message={(location, action) => {
					return location.pathname === window.location.pathname
						? true
						: `Tens canvis sense desar, segur que vols sortir?`
				}}
			/>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<FormControlLabel
					control={<Switch
						checked={autoSave}
						onChange={() => setAutoSave(!autoSave)}
						name="autosave"
						inputProps={{'aria-label': 'secondary checkbox'}}
					/>}
					label={"Autodesar " + lastSaved}
				/>

				<Button color={'primary'} variant={'contained'} disabled={!hasChanged}
						onClick={() => saveTable()}>Desar</Button>
			</div>
			<EditableTable
				rows={rows}
				columns={columns}
				editable={true}
				insertable={false}
				onRowAdded={() => setHasChanged(true)}
				onRowDeleted={() => setHasChanged(true)}
				onEditedTable={(editedRows) => handleEditedRows(editedRows)}
				emptyRow={emptyRow}
				addNewRow={cleanRow}
				isLoading={isLoading}
			/>
			<p>La taula es preomple amb els valors de Títol principal, subtítol, Any LSDA i SuperSardana.</p>
			<p><b>IMPORTANT:</b> L'autodesat o el desat no funcionarà fins que es completin tots els camps de tipus data
				de la taula.</p>
			<p>Exemples i significat de les columnes. Els camps en <b>negreta</b> són obligatoris:</p>
			<ul>
				<li><b>T&iacute;tol principal: </b>Exemple: La Sardana de l'Any 2019. Apareix als PDFs amb els
					resultats.
				</li>
				<li><b>Subt&iacute;tol:</b> Exemple: 31a edició - Any 2019. Apareix al web.</li>
				<li><b>Any LSDA: </b>Any identificador de la temporada del concurs. No es pot modificar.</li>
				<li>SúperSardana: Per defecte a fals.</li>
				<li><b>Data d'inici: </b>Data d'inici del concurs. Ha de coincidir amb la data d'inici de la primera
					eliminatòria.
				</li>
				<li><b>Data final eliminat&ograve;ria: </b> Data de final del concurs. Ha de coincidir o ser posterior a
					la data de final de la última eliminatòria
				</li>
				<li><b>Data inici registre:</b> Data d'inici de registre per la votació telemàtica al concert. Si no hi
					ha votació telemàtica, especificar una data posterior a la de final d'eliminatòries.
				</li>
				<li><b>Data final registre: </b>Data de final de registre per la votació telemàtica al concert. Si no hi
					ha votació telemàtica, especificar una data posterior a la de final d'eliminatòries.
				</li>
				<li>URL final tickets: Enllaç per la venda d'entrades del concert final (si n'hi ha).</li>
				<li>URL final streaming: Enllaç per seguir en streaming el concert final (si n'hi ha).</li>
			</ul>
			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>
		</BodyNewSeasonScreen>
	)
};

export default DadesTemporadaScreen;
