import {getNextFridayCalendar, getNextMondayCalendar} from "../../utils/utils";
import moment from "moment";

export const calendarColumns = [
	{field: "id", headerName: "ID", hide: true},
	{
		field: "ordreEmissio",
		description: "Ordre emissió",
		width: 75,
		headerName: "O. em.",
		type: "number",
		headerAlign: "left",
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		sortable: false,
	},
	{
		field: "ordreResultats",
		description: "Ordre resultats",
		width: 80,
		headerName: "O. res.",
		type: "number",
		headerAlign: "left",
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		sortable: false,
	},
	{
		field: "tipus",
		width: 105,
		headerName: "Tipus",
		type: "singleSelect",
		valueOptions: [],
		headerAlign: "left",
		align: 'left',
		valueFormatter: (params) => {
			const element = params.row?.eliminatoriesValues?.find(el => parseInt(el.value) === parseInt(params.value));
			if (element) return element.label;
			else return "";
		},
		disableColumnMenu: true,
		disableReorder: true,
		sortable: false,
	},
	{
		field: "nOrdreTipus",
		description: "Número ordre tipus",
		width: 70,
		headerName: "N. tip.",
		type: "number",
		headerAlign: "left",
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		sortable: false,
	},
	{
		field: "sardanes",
		width: 180,
		headerName: "Sardanes (màx 6)",
		customType: "multipleSelect",
		maxSelect: 6,
		headerAlign: "left",
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		flex: 1,
	},
	{
		field: "dataInici",
		description: "Data d'inici",
		width: 115,
		headerName: "D. ini.",
		type: "date",
		cellClassName: (params) => {
			const {tipus, dataInici} = params.api.getRowParams(params.id).row;
			const day = new Date(dataInici).getDay();
			if (day !== 5 && tipus === "1") {
				return "orange";
			}
		},
		valueFormatter: (params) => {
			const valueFormatted = moment(params.value).format("dd. DD/MM/YYYY")
			return `${valueFormatted}`;
		},
		headerAlign: "left",
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
	},
	{
		field: "dataFinal",
		description: "Data final",
		width: 115,
		headerName: "D. fi.",
		type: "date",
		cellClassName: (params) => {
			const {tipus, dataFinal} = params.api.getRowParams(params.id).row;
			const day = new Date(dataFinal).getDay();
			if (day !== 1 && tipus === "1") {
				return "orange";
			}
		},
		valueFormatter: (params) => {
			const valueFormatted = moment(params.value).format("dd. DD/MM/YYYY")
			return `${valueFormatted}`;
		},
		headerAlign: "left",
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
	},
];

export const calendarRows = [
	{
		id: 1,
		ordreEmissio: 1,
		width: 180,
		ordreResultats: 1,
		tipus: "Opt 1",
		nOrdreTipus: 1,
		sardanes: {
			selected: [{value: "opt-3", label: "Opt 3"}],
			available: [
				{value: "opt-1", label: "Opt 1"},
				{value: "opt-2", label: "Opt 2"},
				{value: "opt-3", label: "Opt 3"},
			],
		},
		dataInici: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
		dataFinal: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
	},
	{
		id: 2,
		ordreEmissio: 2,
		ordreResultats: 2,
		tipus: "Opt 2",
		nOrdreTipus: 2,
		sardanes: {
			selected: [
				{value: "opt-1", label: "Opt 1"},
				{value: "opt-2", label: "Opt 2"},
			],
			available: [
				{value: "opt-1", label: "Opt 1"},
				{value: "opt-2", label: "Opt 2"},
				{value: "opt-3", label: "Opt 3"},
			],
		},
		dataInici: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
		dataFinal: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
	},
];

export const emptyRowCalendari = (availableSardanes, eliminatories) => {

	let monday = 1;
	let friday = 5;
	let actualDay = moment().isoWeekday();
	let start;
	let end;

	if (actualDay < friday) {
		start = moment().isoWeekday(friday);
		end = moment().add(1, 'weeks').isoWeekday(monday);
	} else {
		start = moment().add(1,"weeks").isoWeekday(friday);
		end = moment().add(2,"weeks").isoWeekday(monday);
	}

	return {
		id: "1",
		ordreEmissio: "1",
		ordreResultats: "1",
		tipus: "1",
		nOrdreTipus: "1",
		sardanes: {
			selected: [],
			available: availableSardanes,
		},
		dataInici: start,
		dataFinal: end,
		eliminatoriesValues: eliminatories
	}
}

export const createNewRowCalendari = (lastRow, availableSardanes, eliminatories, allRows) => {

	console.log("lastRow", lastRow);
	let newRow = emptyRowCalendari(availableSardanes, eliminatories);
	if (!lastRow) {
		console.log("newRow", newRow)
		return newRow;
	} else {
		let ids = [];
		allRows.forEach(el => ids.push(parseInt(el.id)));
		let nextId = Math.max(...ids) + 1;
		newRow = {
			...lastRow,
			id: nextId,
			ordreEmissio: parseInt(lastRow?.ordreEmissio) + 1,
			ordreResultats: parseInt(lastRow?.ordreResultats) + 1,
			tipus: lastRow?.tipus,
			nOrdreTipus: parseInt(lastRow?.nOrdreTipus) + 1,
			sardanes: {
				selected: [],
				available: availableSardanes,
			},
			dataInici: getNextFridayCalendar(lastRow?.dataFinal).toDate(),
			dataFinal: getNextMondayCalendar(lastRow?.dataFinal).toDate(),
		}
		return newRow;
	}
};
