export const faseRegularDetailColumns = [
	{field: "id", headerName: "ID", hide: true},
	{
		field: "sardana",
		flex: 1,
		headerName: "Sardana",
		type: "string",
		editable: false
	},
	{
		field: "autor",
		width: 160,
		headerName: "Autor",
		type: "string",
		align: 'left',
		disableColumnMenu: true,
		disableReorder: true,
		editable: false,
	},
	{
		field: "resultats",
		width: 180,
		headerName: "Punts",
		type: "number",
		headerAlign: 'left',
		align: 'left',
	},
	{
		field: "vots",
		width: 220,
		hide: true,
		headerName: "% de vots",
		type: "string",
	}
];
