export const sardanesColumns = [
	{field: "id", headerName: "ID", hide: true},
	{
		field: "titol",
		width: 220,
		headerName: "Títol",
		type: "string",
		flex: 1,
	},
	{
		field: "autor",
		width: 180,
		headerName: "Autor",
		type: "string",
	},
	{
		field: "cobla",
		width: 220,
		headerName: "Cobla",
		type: "string",
	}
];

export const sardanesRows = [
	{
		id: 1,
		titol: "Muntanyes de fusta",
		width: 180,
		autor: "Xavi Piñol",
		cobla: "Principal del Llobregat",
		audio: "audio1",

	},
	{
		id: 2,
		titol: "1987",
		width: 180,
		autor: "Gerard Pastor",
		cobla: "jovenívola d'Agramunt",
		audio: "audio2",
	},
	{
		id: 3,
		titol: "Ho tornarem a fer!",
		width: 180,
		autor: "Concepció Ramió",
		cobla: "Sant Jordi - Ciutat de Barcelona",
		audio: "audioNaN",
	},

];
