import axiosService from "../../services/axiosService";


export const getFasesRegularsApi = (year = "working") => {
	return axiosService.get(
		"/temporada-detalls/" + year + "/?s=calendari"
	)
}

export const getResultatsFaseRegularsApi = (idElim, year = "working") => {
	return axiosService.get(
		"/temporada-detalls/" + year + "/?s=resultats_elim&idElim="  +idElim
	)
}

export const updateResultatsFaseRegularApi = (data, year = "working") => {
	return axiosService.post(
		"/temporada_detalls/" + year + "/?s=resultats_elim", data
	)
}


export const publishResultatsFaseRegularApi = (idElim, year = "working") => {
	return axiosService.post(
		"/temporada-detalls/" + year + "/?s=publicar_resultats_elim&idElim="+idElim+"&publish=1"
	)
}

export const simulationFaseRegularApi = (urlBase, day, month, year) => {
	return axiosService.get(
		`${urlBase}&dia=${day}&mes=${month}&any=${year}`
	)
}
