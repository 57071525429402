import axiosService from "../services/axiosService";

export const getCalendariApi = (year = "working") => {

	return axiosService.get(
		"/temporada_detalls/" + year + "/?s=calendari"
	)

}

export const updateCalendariApi = (calendariRows, year = "working") => {
	console.info("calendariRows", calendariRows)
	return axiosService.post(
		  "/temporada_detalls/" + year + "/?s=calendari", calendariRows
	)
}
