export const sardanesClassificadesColumns = [
	{
		field: "nOrdre",
		width: 120,
		headerName: "Ordre",
		type: "number",
		headerAlign: "left",
		align: 'left',
		disableReorder: true,
		editable: false
	},
	{
		field: "idSardana",
		width: 240,
		headerName: "Sardana",
		type: "singleSelect",
		valueOptions: [],
		flex: 1,
		headerAlign: "left",
		align: 'left',
		valueFormatter: (params) => {
			const element = params.colDef?.allSardanes?.find(el => parseInt(el.value) === parseInt(params.value));
			if (element) return element.label;
			else return "";
		},
		disableColumnMenu: true,
		disableReorder: true,
		sortable: false,
	},


];

export const collaboradorsRows = [];
