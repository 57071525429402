export default {
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1
	},
	tableHeader: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 24
	},
	tableTitle: {
		marginBlockStart: 0,
		fontSize: 16,
		fontWeight: 'bold',
	},
	tableFooter: {
		display: "flex",
		width: "100%",
		alignItems: "center",
		marginTop: 24
	},
	tableTotalRows: {
		display: "inline-block"
	},
	button: {
		marginTop: "1rem",
		marginLeft: "auto",
	}
}
