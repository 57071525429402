import React, {useEffect, useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {getSardanesClassificadesService} from "../../../data/services/concertsService";

const SardanesClassificades = ({history, rows}) => {

	const [sardanesClassificadesRows, setSardanesClassificadesRows] = useState([]);

	useEffect(() => {
		getSardanesClassificadesService()
			.then(res => {
				setSardanesClassificadesRows(res);
			})
			.catch(e => {
				console.error(e);
			})
	}, [])

	return (
		<>
			<p style={{fontSize: 22, fontWeight: "bold", color: "grey"}}>
				Sardanes classificades (resultat eliminatòries)
			</p>

			{sardanesClassificadesRows.length > 0
				? <TableContainer>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Nº</TableCell>
								<TableCell align="left">Nom sardana</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sardanesClassificadesRows.map((row, index) => (
								<TableRow key={row.id}>
									<TableCell component="th" scope="row" style={{maxWidth: 200}}>
										{index + 1}
									</TableCell>
									<TableCell align="left">{row.name}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				: <p>Cal introduïr a la base de dades la <a href="/editar-temporada/sardanes-classificades">llista de
					sardanes classificades</a></p>
			}

		</>
	)
}

export default SardanesClassificades;
