import React, {useEffect, useState} from "react";
import BodyNewSeasonScreen from "../../components/composed/BodyNewSeason/BodyNewSeason";
import EditableTable from "../../components/composed/EditableTable/EditableTableComponent/EditableTable";
import {emissoresColumns} from "./emissoresTableContent";
import {cleanRow} from "../../utils/utils";
import {Button, FormControlLabel, Snackbar, Switch} from "@material-ui/core";
import {getEmissoresService, updateEmissoresService} from "../../data/services/editarTemporada/emissoresService";
import {SNACKBAR_MUI_TYPES, SNACKBAR_STRINGS, SNACKBAR_TYPES} from "../../utils/constants";
import Alert from "@material-ui/lab/Alert";
import {Prompt} from "react-router-dom";
import moment from "moment";

const LlistatEmisoresScreen = () => {

	const [hasChanged, setHasChanged] = useState(false);

	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);

	const [autoSave, setAutoSave] = useState(true);
	const [lastSaved, setLastSaved] = useState("");


	const [isLoading, setIsLoading] = useState(false);

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("info");
	const [snackBarText, setSnackBarText] = useState("snackBar");


	useEffect(() => {
		setIsLoading(true)
		getEmissoresService()
			.then(res => {
				setColumns(emissoresColumns)
				setRows(res.rows)
				setIsLoading(false)
			})
			.catch(err => {
				console.error("[LlistatEmissoresScreen] error fetching emissores rows", err)
			})
	}, [])


	const saveTable = (silent = false, refreshContent = true, editedRows = null) => {
		if (!silent) setIsLoading(true)
		updateEmissoresService(editedRows ? editedRows : rows)
			.then(res => {
				if (refreshContent) {
					setRows(res.rows)
				}
				if (!silent) {
					setIsLoading(false)
					showSetupSnackBar(SNACKBAR_TYPES.SUCCESS)
				}
				setLastSaved("Últim desat a les " + moment().format("HH:mm:ss"))
			})
			.catch(err => {
				console.error("[LlistatEmissoresScreen] error uploading emissores rows", err)
				if (!silent) {
					showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
				}
				setIsLoading(false)
			})
		if (refreshContent) {
			setHasChanged(false)
		}
	}


	const handleCopyToClipboard = () => {
		let emails = ""
		rows.map((row) => {
			if (row.email) {
				emails = emails + row.email + ', '
			}
			if (row.email_alternatiu) {
				emails = emails + row.email_alternatiu + ', '
			}
		})
		//remove last comma and space and copy to clipboard
		//navigator.clipboard.writeText(emails.slice(0,-2)).then(r => console.log("Alguna cosa ha fallat", r))
		navigator.clipboard.writeText(emails.slice(0, -2)).then(function () {
			/* clipboard successfully set */
			showSetupSnackBar(SNACKBAR_TYPES.SUCCESS_CLIPBOARD)
			console.info("[LlistatEmissoresScreen] Clipboard write succesful")
		}, function () {
			/* clipboard write failed */
			console.error("[LlistatEmissoresScreen] Clipboard write failed")
		});
	}

	const showSetupSnackBar = (type) => {

		switch (type) {
			case SNACKBAR_TYPES.SUCCESS:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.SUCCESS_CLIPBOARD:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS_CLIPBOARD)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_GET_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_SEND_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			default:
				setSnackBarType("info")
				setSnackBarText("default switch")
				setShowSnackBar(true)

		}
	}

	const handleEditedRows = (editedRows) => {
		setRows(editedRows)
		setHasChanged(true)
		//console.info("[SardanesScreen] edited rows",editedRows)

		if (autoSave) saveTable(true, true, editedRows);
	}

	return (
		<BodyNewSeasonScreen>
			<Prompt
				when={hasChanged}
				message={(location, action) => {
					return location.pathname === window.location.pathname
						? true
						: `Tens canvis sense desar, segur que vols sortir?`
				}}
			/>

			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<FormControlLabel
					control={<Switch
						checked={autoSave}
						onChange={() => setAutoSave(!autoSave)}
						name="autosave"
						inputProps={{'aria-label': 'secondary checkbox'}}
					/>}
					label={"Autodesar " + lastSaved}
				/>

				<Button color={'primary'} variant={'contained'} disabled={!hasChanged}
						onClick={() => saveTable()}>Desar</Button>
			</div>
			<EditableTable
				rows={rows}
				columns={emissoresColumns}
				editable={true}
				deletable={true}
				insertable={true}
				onRowAdded={() => setHasChanged(true)}
				onRowDeleted={() => setHasChanged(true)}
				onEditedTable={(editedRows) => handleEditedRows(editedRows)}
				addNewRow={cleanRow}
				isLoading={isLoading}
				emptyRow={{
						id: "",
						nom: "",
						email: "",
						responsable_emissora: "",
						email_alternatiu: "",
						nom_programa: "",
				}}
			/>

			<Button
				variant={"outlined"}
				color={"secondary"}
				onClick={handleCopyToClipboard}
			>
				Copiar correus al porta retalls
			</Button>

			<p><b>Nota informativa:</b> tots els correus electrònics dels <b>informadors</b> d’aquesta llista rebran els
				resultats de les diferents fases regulars de forma automàtica.</p>

			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>

		</BodyNewSeasonScreen>


	)
};

export default LlistatEmisoresScreen;
