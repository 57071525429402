import axiosService from "../services/axiosService";

export const getConcertsApi = () => {
	return axiosService.get(
		"/concert"
	)
};

export const updateConcersApi = (data) => {
	return axiosService.post(
		"/concert",
		data
	)
};

export const getPassKeywords = () => {
	return axiosService.get(
		"/passKeywords",
	)
}

export const getSardanesClassificades = () => {
	return axiosService.get(
		"/sardanesClassificades",
	)
}

export const actionsVoteAPI = (type) => {
	let actionURL = "/concert/action/" + type

	return axiosService.post(actionURL)
}
