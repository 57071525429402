export const emissoresFormatterFromApi = (data) => {
	let r = []
	data.map((emiss) => {
		console.log(emiss.correuEmissora)
		r.push(
			{
				id: emiss.idEmissora,
				nom: emiss.nomEmissora,
				email: emiss.correuEmissora,
				responsable_emissora: emiss.responsable,
				email_alternatiu: emiss.correuAlternatiu,
				nom_programa: emiss.programaRadio,
			}
		)
	})

	return {

		rows: r,
	}
};

export const emissoresFormatterToApi = (emissoresRows) => {

	let forApiRows = []
	emissoresRows.map((row, index) => {
		forApiRows.push(
			{
				idEmissora: index + 3,
				nomEmissora: row.nom,
				correuEmissora: row.email,
				responsable: row.responsable_emissora,
				correuAlternatiu: row.email_alternatiu,
				programaRadio: row.nom_programa,
			}
		)
	})
	console.info("forApiRows", forApiRows)
	return forApiRows
};
