import {getSardanesApi, updateSardanesApi} from "../../api/editarTemporada/sardanesApi";
import {sardanesFormatterFromApi, sardanesFormatterToApi} from "../../formatters/editarTemporada/sardanesFormatter";

export const getSardanesService = () => {
	return new Promise((resolve, reject) => {
		getSardanesApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(sardanesFormatterFromApi(responseData))
				} else {
					throw new Error("[SardanesService] error getting sardanes rows data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
};

export const updateSardanesService = (sardanesRows) => {
	return new Promise((resolve, reject) => {
		updateSardanesApi(sardanesFormatterToApi(sardanesRows))
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(sardanesFormatterFromApi(responseData))
				} else {
					throw new Error("[sardanesService] Update sardanes rows error")
				}
			})
			.catch(error => {
				console.error("[sardanesService] Update sardanes rows error", error)
				reject(error.toString())
			})
	})
}
