import moment from "moment";

export const calendariFormatterFromApi = (data) => {
	console.log("data calendariformatterFromApi", data)
	return {

		rows: data.res,
	}
};

const getSardanaIdAtPosition = (sardanes, position) => {
	try {
		if (sardanes[position]) return sardanes[position].value;
		else throw new Error("No existeix");
	}
	catch (e) {
		return "0";
	}
}

export const updateCalendariFormatterToApi = (calendarRows) => {
	console.info("BeforeFormatter", calendarRows)
	let res = []
	let lastId = 1;
	calendarRows.forEach(row => {
		let curRow = {
			idElim: row.id ? row.id : parseInt(lastId)+1,
			nOrdreEmissio: row.ordreEmissio,
			nOrdreResultats: row.ordreResultats,
			idTipus: row.tipus,
			nOrdreTipus: row.nOrdreTipus,
			dInici: moment(row.dataInici).format("YYYY-MM-DD"),
			dFinal: moment(row.dataFinal).format("YYYY-MM-DD"),
			idSardana1: getSardanaIdAtPosition(row.sardanes.selected, 0),
			idSardana2: getSardanaIdAtPosition(row.sardanes.selected, 1),
			idSardana3: getSardanaIdAtPosition(row.sardanes.selected, 2),
			idSardana4: getSardanaIdAtPosition(row.sardanes.selected, 3),
			idSardana5: getSardanaIdAtPosition(row.sardanes.selected, 4),
			idSardana6: getSardanaIdAtPosition(row.sardanes.selected, 5),
		};
		lastId = curRow.id ? curRow.id : lastId;

		res.push(curRow);
	})
	return res;
}
