import React from "react";
import Header from "../../components/composed/Header/Header";

const MainPage = () => {
	return (
		<>
			<Header/>
			<div style={{flex: 1, display: "flex", justifyContent: "center", alignContent: "center"}}>
				<p style={{display: "flex", alignSelf: "center"}}>Pàgina inicial. Selecciona una opció en el menú
					superior.</p>
			</div>
		</>
	)
}

export default MainPage;
