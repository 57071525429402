import React, {useEffect, useState} from 'react';
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {FormControl, InputLabel, MenuItem, Modal} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";


const ModalEditSardana = ({
							  orderSardanaEditable, onClickSave, onClickDelete,
							  open = false, handleOnCloseModal, sardanes = [],
							  currentOrdre, currentSardana, currentVots,
						  }) => {


	const [ordre, setOrdre] = useState(currentOrdre);
	const [sardana, setSardana] = useState(currentSardana);
	const [vots, setVots] = useState(currentVots);

	useEffect(() => {
		if (!currentOrdre) return;
		setOrdre(currentOrdre)
		let sardanaObj = sardanes.find(sardana => currentSardana === sardana.name)
		if (sardanaObj) setSardana(sardanaObj.id)
		setVots(currentVots)
	}, [currentOrdre, currentSardana, currentVots])


	const submitForm = () => {
		let sardanaObj = sardanes.find(sard => sard.id === sardana);
		onClickSave(ordre, sardanaObj.name, vots, sardana)
	}


	const handleOrdreChange = (e) => {
		setOrdre(e.target.value)
	}


	const handleSardanaChange = (e) => {
		setSardana(e.target.value)
	}


	const handleVotsChange = (e) => {
		setVots(e.target.value)
	}


	return (

		<>
			<Modal
				open={open}
				onClose={handleOnCloseModal}
			>

				<Fade in={open}>
					<div style={{
						display: "flex",
						flex: 1,
						height: "100vh",
						width: "100vw",
						alignItems: "center",
						justifyContent: "center"
					}}>
						<Paper elevation={3}
							   style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 40}}>

							<h2>Editar sardana</h2>

							<form onSubmit={(event) => {
								event.preventDefault();
								submitForm()
							}}>

								<TextField label={"Ordre"} id="posicio" variant="outlined"
										   value={ordre} disabled={!orderSardanaEditable} type="text"
										   onChange={handleOrdreChange}/>


								<br/>
								<FormControl variant="outlined" style={{marginTop: 30}}>
									<InputLabel>Sardana</InputLabel>
									<Select style={{minWidth: 300}} variant={"outlined"} label="Sardana"
											id="select-sardana" value={sardana} disabled={!orderSardanaEditable}
											onChange={handleSardanaChange}
									>
										{sardanes.map((sardana) =>
											<MenuItem value={sardana.id}>{sardana.name}</MenuItem>
										)}

									</Select>
								</FormControl>

								<br/>
								{vots === null || vots === undefined
									? null
									: <FormControl variant="outlined" style={{marginTop: 30}}>
										<TextField labelId="Punts" label="vots" id="vots" variant="outlined"
												   defaultValue={vots} type="text" onChange={handleVotsChange}/>
									</FormControl>
								}


								<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>

									<Button style={{display: "flex", marginTop: 35, marginLeft: 200}}
											variant="contained" onClick={handleOnCloseModal}>
										Cancel·lar
									</Button>

									<Button style={{display: "flex", marginTop: 35, marginLeft: 8}} variant="contained"
											color="primary" type="submit">
										Desar
									</Button>
								</div>

							</form>

						</Paper>
					</div>
				</Fade>

			</Modal>
		</>
	);


};


export default ModalEditSardana;
