import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import {Button, CircularProgress, Snackbar} from "@material-ui/core";
import React, {useState} from "react";
import Alert from "@material-ui/lab/Alert";
import {uploadAudioService} from "../../data/services/editarTemporada/fitxersService";

const AfegeixTab = ({ loadData}) => {

	const [isUploading, setIsUploading] = useState(false)

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("");
	const [snackBarText, setSnackBarText] = useState("");

	const handleAudioFileSelected = (event) => {
		setSnackBarType("info")
		setSnackBarText("Carregant fitxer...")
		setShowSnackBar(true);
		setIsUploading(true)

		let promises = [];

		Array.from(event.target.files).forEach(file => {
			promises.push(uploadAudioService(file))
		})

		Promise.all(promises).then(r => {
			setShowSnackBar(true);
			setSnackBarType("success")
			setSnackBarText("Fitxer carregat correctament")
			setIsUploading(false)
			loadData();
		})
			.catch(err => {
				setShowSnackBar(true);
				setSnackBarType("error")
				setSnackBarText("Error carregant fitxer")
				setIsUploading(false)
				console.error("[AfegeixTab] error uploading file: ", err);
			})


	}


	return (
		<div style={{display: "flex", flexDirection: "column", flex: 1}}>
			<div style={{display: "flex", flex: 1, margin: "30px", backgroundColor: "lightgray"}}>

				<div style={{
					display: "flex",
					flex: 1,
					alignItems: "center",
					justifyContent: "center",
					border: "dotted gray"
				}}>
					<form action="" method="post" encType="multipart/form-data">
						<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>

							<span>Selecciona fitxers</span>
							<SystemUpdateAltIcon fontSize={"large"} style={{marginTop: "10px"}}/>
							<Button
								style={{marginTop: "30px"}}
								variant="contained"
								component="label"
								startIcon={isUploading && <CircularProgress size={"1.3rem"}/>}
							>
								Seleccionar...
								<input
									multiple="multiple"
									type="file"
									onChange={(event) => handleAudioFileSelected(event)}
									hidden
								/>
							</Button>

						</div>

					</form>
				</div>

			</div>
			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>
		</div>
	)

}

export default AfegeixTab
