export const eliminatoriesColumns = [
	{field: "id", headerName: "ID", hide: true},
	{
		field: "titol",
		width: 220,
		headerName: "Tipus d'eliminatòria",
		type: "string",
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
	},
	{
		field: "tipus",
		width: 220,
		headerName: "Eliminatòria actual",
		type: "string",
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
	},
	{
		field: "nom",
		width: 140,
		headerName: "Títol curt",
		type: "string",
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
	},
	{
		field: "num_sardanes",
		width: 140,
		headerName: "Nº sardanes",
		type: "number",
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
	},
	{
		field: "sardanes_visibles",
		width: 150,
		headerName: "Sardanes visibles",
		sortable: false,
		filterable: false,
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		type: 'boolean'
	},
	{
		field: "vots_blanc",
		width: 180,
		headerName: "Permetre vots en blanc",
		sortable: false,
		filterable: false,
		disableReorder: true,
		type: 'boolean',
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
	},
	{
		field: "admetre_votacio",
		width: 140,
		headerName: "Admetre votació",
		sortable: false,
		filterable: false,
		disableReorder: true,
		type: 'boolean',
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
	},
	{
		field: "mostrar_ordinal",
		width: 140,
		headerName: "Mostrar Ordinal",
		sortable: false,
		filterable: false,
		disableReorder: true,
		type: 'boolean',
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
	},
	{
		field: "comentari",
		width: 560,
		headerName: "Comentari",
		type: "string",
	},
];

export const eliminatoriesRows = [
	{
		id: 1,
		titol: "Eliminatòria",
		tipus: "eliminatoria",
		width: 180,
		nom: "ELIM",
		num_sardanes: 4,
		sardanes_visibles: {
			value: true,
			onChange: null
		},
		vots_blanc: {
			value: true,
			onChange: null
		},
		admetre_votacio: {
			value: true,
			onChange: null
		},
		mostrar_ordinal: {
			value: true,
			onChange: null
		},
		comentari: "comentari 1",

	},
	{
		id: 2,
		titol: "Semifinals",
		tipus: "patata",
		width: 180,
		nom: "SEMI",
		num_sardanes: 4,
		sardanes_visibles: {
			value: false,
			onChange: null
		},
		vots_blanc: {
			value: true,
			onChange: null
		},
		admetre_votacio: {
			value: true,
			onChange: null
		},
		mostrar_ordinal: {
			value: true,
			onChange: null
		},
		comentari: "comentari 2",

	},
	{
		id: 3,
		titol: "Premi Joventut",
		tipus: "eliminatoria",
		width: 180,
		nom: "JOVE",
		num_sardanes: 4,
		sardanes_visibles: {
			value: true,
			onChange: null
		},
		vots_blanc: {
			value: true,
			onChange: null
		},
		admetre_votacio: {
			value: true,
			onChange: null
		},
		mostrar_ordinal: {
			value: true,
			onChange: null
		},
		comentari: "comentari 3",

	},

];
