import {Button, Card, CardContent, InputAdornment, Paper, TextField, Typography} from "@material-ui/core";
import {Comment, FiberManualRecord, RecordVoiceOver, Search} from "@material-ui/icons";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import React, {useEffect, useRef, useState} from "react";


const MediatecaTab = ({tabs, handleOnTabChange, selectedTab, handleFileNameChange, audioFiles, handleDesar }) => {
	const audioRef = useRef(null);

	const [selectedFileIndex, setSelectedFileIndex] = useState(null);
	const [filteredAudioFiles, setFilteredAudioFiles] = useState(audioFiles);
	const [searchFilter, setSearchFilter] = useState("");

	useEffect(() => {
		filterFiles(searchFilter);
	}, [audioFiles])

	const onCLickDesar = () => {
		setSelectedFileIndex(null);
		handleDesar()
	}

	const handleAudioFileClick = (index) => {
		setSelectedFileIndex(index)
		audioRef.current.pause();
		audioRef.current.load();
	}

	const filterFiles = (itemToFind) => {

		setFilteredAudioFiles(
			audioFiles.filter((file) => {
				return (
					file.asociatedSardanaName.toLowerCase().includes(itemToFind.toLowerCase()) ||
					file.filename.toLowerCase().includes(itemToFind.toLowerCase())
				)
			})
		)
	}

	const handleSearch = (e) => {
		setSearchFilter(e.target.value);
		if (e.target.value === "") setSelectedFileIndex(null)
		filterFiles(e.target.value);
	}

	const renderCardIcon = (file) => {
		if (file.filename.includes("0000") || file.filename.includes("ZZZZ")) {
			return <Comment fontSize="large" color={!file.eliminatoria ? "error" : "default"}/>
		}
		if (file.filename.includes("PRES")) {
			return <RecordVoiceOver fontSize="large" color={!file.eliminatoria ? "error" : "default"}/>
		}
		else {
			return <MusicNoteIcon fontSize="large" color={!file.eliminatoria ? "error" : "default"}/>
		}
	}


	return (
		<Paper style={{display: "flex", flexDirection: "row", padding: 20, marginTop: 8}} elevation={0}>
			<div style={{display: "flex", flexDirection: "column", flex: 1}}>
				<div style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					marginLeft: 16
				}}>

					<TextField
						InputProps={{
							startAdornment: <InputAdornment position="start">
							<Search/>
						</InputAdornment>}}
						id={'textField'}
						label="Cercar..."
						value={searchFilter}
						onChange={handleSearch}
					/>
				</div>

				<div style={{display: "flex", flexWrap: "wrap", justifyContent: "flex-start", marginTop: "20px"}}>

					{filteredAudioFiles.length === 0 && <p>No hi ha cap fitxer carregat</p>}
					{filteredAudioFiles.map((file, index) =>

						<div key={"media"+index} style={{
							display: "flex", flexDirection: "column",
							alignItems: "center", width: "140px", gap: 8
						}}
							 onClick={(e) => {
								 e.stopPropagation();
								 handleAudioFileClick(index)
							 }}>
							<Card style={{
								alignItems: "center",
								display: "flex",
								justifyContent: "center",
								height: "100px",
								width: "100px",
								backgroundColor: index === selectedFileIndex ? "lightgray" : "transparent",
								border: index === selectedFileIndex ? "solid orange 4px" : "solid transparent 4px"
							}}>
								<CardContent>
									{renderCardIcon(file)}
								</CardContent>
							</Card>
							<span style={{textAlign: "center", marginBottom: 8, marginLeft: 4, marginRight: 4, fontSize: 12}}>{file.filename}</span>
						</div>
					)}

				</div>
				<br/>
				<br/>
				<h4>Llegenda</h4>
				<ul>
					<li><FiberManualRecord fontSize="small"/> Color negre: l'àudio té una eliminatòria associada.</li>
					<li><FiberManualRecord fontSize="small" color={"error"}/> Color vermell: l'àudio no té una eliminatòria associada. Això vol dir que si no es corregeix l'error, aquest àudio no es reproduirà en el concurs.</li>
					<li><Comment fontSize="small"/> Àudio d'introducció o final de l'eliminatòria</li>
					<li><RecordVoiceOver fontSize="small"/> Àudio que és la presentació d'una sardana</li>
				</ul>
			</div>

			<Paper elevation={0} style={{marginLeft: "30px"}}>

				<div style={{display: "flex", flexDirection: "column"}}>

					<div style={{display: "flex", flexDirection: "column", marginTop: "20px"}}>
						<TextField id="file-name" label="Nom de l'arxiu" variant="outlined"
								   onChange={(e) => {
									   handleFileNameChange(e.target.value, filteredAudioFiles[selectedFileIndex])
								   }}
								   value={selectedFileIndex !== null ? filteredAudioFiles[selectedFileIndex]?.filename : ""}/>
					</div>

					{filteredAudioFiles[selectedFileIndex]?.asociatedSardanaName &&
					<div style={{display: "flex", flexDirection: "column", marginTop: "20px"}}>
						<TextField id="file-name" disabled label="Sardana associada" variant="outlined"
								   value={selectedFileIndex !== null ? filteredAudioFiles[selectedFileIndex]?.asociatedSardanaName : ""}/>
					</div>}
					<div style={{display: "flex", flexDirection: "column", marginTop: "20px"}}>
						<TextField id="file-name" disabled label="Eliminatoria associada" variant="outlined"
								   value={selectedFileIndex !== null ? filteredAudioFiles[selectedFileIndex]?.eliminatoria : ""}/>
					</div>

					<b style={{marginTop: "10px"}}>Escoltar</b>

					<Card style={{margin: "10px 10px 10px 0px", backgroundColor: "lightgray", cursor: "pointer"}}>
						<div>
							<CardContent>
								<Typography component="span" variant="span">
									{selectedFileIndex !== null ? filteredAudioFiles[selectedFileIndex]?.filename : ""}
								</Typography>
							</CardContent>
							<div style={{margin: "0px 6px 8px 6px"}}>
								<audio controls autoPlay={false} ref={audioRef}>
									<source
										src={selectedFileIndex !== null ? filteredAudioFiles[selectedFileIndex]?.audioURL : ""}
										type="audio/mpeg"/>
									El teu navegador no suporta arxius d'àudio.
								</audio>
							</div>
						</div>
					</Card>

					<div style={{display: "flex", flex: 1, justifyContent: "flex-end"}}>
						<Button type={"submit"} variant={"contained"}
								onClick={onCLickDesar}
								style={{backgroundColor: "orange", marginTop: "20px", maxWidth: 80}}>
							Desar
						</Button>
					</div>

				</div>

			</Paper>
		</Paper>
	)
}

export default MediatecaTab;
