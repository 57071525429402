export const usersColumns = [
	{field: "id", headerName: "ID", hide: true},
	{
		field: "username",
		width: 240,
		headerName: "Nom d'usuari",
		type: "string",
	},
	{
		field: "email",
		width: 300,
		headerName: "Correu electrònic",
		type: "string",
	},
	{
		field: "is_admin",
		width: 150,
		headerName: "Administrador",
		type: "boolean",
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
	},
	{
		field: "status",
		width: 90,
		headerName: "Actiu",
		type: "boolean",
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
	},
];

export const emissoresRows = [
	{
		id: 1,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",

	},
	{
		id: 2,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,

	},
	{
		id: 3,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",

	},
	{
		id: 4,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",

	},
	{
		id: 5,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,

	},
	{
		id: 6,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",

	},
	{
		id: 7,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",

	},
	{
		id: 8,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,

	},
	{
		id: 9,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",

	},
	{
		id: 10,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",

	},
	{
		id: 11,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,

	},
	{
		id: 12,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",

	},
	{
		id: 13,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",

	},
	{
		id: 14,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,

	},
	{
		id: 15,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",

	},
	{
		id: 16,
		nom: "Ràdio Terrassa",
		width: 180,
		email: "radioterrassa@gmail.com",

	},
	{
		id: 17,
		nom: "Ràdio Sant Pol",
		width: 180,
		email: null,

	},
	{
		id: 18,
		nom: "Ràdio Calella",
		width: 180,
		email: "coblejant@gmail.com",

	},

];
