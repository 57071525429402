import {authenticateApi, logoutAPI, requestNewPasswordApi, setNewPasswordApi} from "../api/authApi";

export const loginService = (username, password) => {
	return new Promise((resolve, reject) => {
		authenticateApi(username, password)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData)
				} else {
					throw new Error("Error in authenticate process")
				}
			})
			.catch(error => {
				console.error("[loginService]: ", error)
				if (error?.response?.data?.error) {
					console.error("[loginService] data: ", error.response.data)
					reject(error.response.data.error);
				}
				else reject(error.toString())
			})
	})
}

export const recoverPasswordFromEmailService = (username) => {
	return new Promise((resolve, reject) => {
		requestNewPasswordApi(username)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData)
				} else {
					throw new Error("Error in recover password process")
				}
			})
			.catch(error => {
				console.error("[recoverPasswordFromEmailService]: ", error)
				if (error?.response?.data?.error) {
					console.error("[recoverPasswordFromEmailService] data: ", error.response.data)
					reject(error.response.data.error);
				}
				else reject(error.toString())
			})
	})
}

export const recoverPasswordSetNewPasswordService = (token, password1, password2) => {
	return new Promise((resolve, reject) => {
		setNewPasswordApi(token, password1, password2)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData)
				} else {
					throw new Error("Error in recover password process")
				}
			})
			.catch(error => {
				console.error("[recoverPasswordSetNewPasswordService]: ", error)
				if (error?.response?.data?.error) {
					console.error("[recoverPasswordSetNewPasswordService] data: ", error.response.data)
					reject(error.response.data.error);
				}
				else reject(error.toString())
			})
	})
}

export const logoutService = (deviceName) => {
	return new Promise((resolve, reject) => {
		logoutAPI(deviceName, 0)
			.then(res => {
				if (res.status === 200) {
					resolve()
				} else {
					throw new Error("Error in logoutService")
				}
			})
			.catch(error => {
				console.error("[logoutService]: ", error)
				if (error?.response?.data?.error) {
					console.error("[logoutService] data: ", error.response.data)
					reject(error.response.data.error);
				}
				else reject(error.toString())
			})
	})
};
