import axiosService from "../../services/axiosService";

export const getAudioFilesApi = (year = "working") => {

	return axiosService.get(
		"/temporada_detalls/" + year + "/?s=audios"
	)
}

export const putAudioFilesApi = (data, year = "working",) => {
	return axiosService.post("/temporada_detalls/" + year + "/?s=audios", data)
}

export const getBasesEmparellamentsApi = (year = "working") => {
	return axiosService.get(
		"/temporada_detalls/" + year + "/?s=bases_emparellaments"
	)
}

export const uploadAudioFileApi = (file, year = "working") => {
	const formData = new FormData();
	formData.append("arxiu", file)
	return axiosService.post(
		"/temporada_detalls/" + year + "/?s=carregarAudio", formData
	)
}


export const uploadBasesFileApi = (file, year = "working") => {
	const formData = new FormData();
	formData.append("arxiu", file)
	return axiosService.post(
		"/temporada_detalls/" + year + "/?s=carregarBases", formData
	)
}


export const uploadEmparellamentsFileApi = (file, year = "working") => {
	const formData = new FormData();
	formData.append("arxiu", file)
	console.log(file)
	return axiosService.post(
		"/temporada_detalls/" + year + "/?s=carregarEmparellaments", formData
	)
}
