import axiosService from "../services/axiosService";
import {CONCERT_PREMIS_TYPES} from "../../utils/constants";

export const getResultatsConcertsApi = (concertType) => {
	return axiosService.get(
		"/concert/" + concertType
	)
}

export const updateResultatsConcertsApi = (sardanes, totalVots = null, nullVots = null, jurat = "") => {
	return axiosService.post(
		"/concert/update/" + CONCERT_PREMIS_TYPES.ALL, {...sardanes, totalVots, nullVots, jurat}
	)
}
