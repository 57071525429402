import React, {useEffect, useState} from "react";
import BodyNewSeasonScreen from "../../components/composed/BodyNewSeason/BodyNewSeason";
import {Button, CircularProgress, Divider, Link, Snackbar} from "@material-ui/core";
import TabBar from "../../components/unit/TabBar/TabBar";
import {
	getAudioFilesService,
	getBasesEmparellamentsService,
	updateAudioFilesService,
	uploadBasesService,
	uploadEmparellamentsService
} from "../../data/services/editarTemporada/fitxersService";
import MediatecaTab from "./MediatecaTab";
import AfegeixTab from "./AfegeixTab";
import Alert from "@material-ui/lab/Alert";
import {SNACKBAR_MUI_TYPES} from "../../utils/constants";
import Prompt from "react-router-dom/es/Prompt";


const FitxersScreen = () => {

	const [hasChanged, setHasChanged] = useState(false);

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("");
	const [snackBarText, setSnackBarText] = useState("");

	const [selectedTab, setSelectedTab] = useState(0);
	const [audioFiles, setAudioFiles] = useState([]);

	const [isLoading, setIsLoading] = useState(true)

	//Files input
	const [basesFilePath, setBasesFilePath] = useState(null);
	const [emparellamentsFilePath, setEmparellamentsFilePath] = useState(null);
	const [audioFilePath, setAudioFilePath] = useState(null);

	useEffect(() => {
		loadData()
	}, [])

	const loadData = () => {
		setIsLoading(true);
		getAudioFilesService()
			.then(res => {
				setAudioFiles(res)
				setIsLoading(false)
			})
			.catch(err => {
				console.log("error fetching audio files", err)
			})
		getBasesEmparellamentsService()
			.then(r => {
				setBasesFilePath(r.bases)
				setEmparellamentsFilePath(r.emparellaments)
				setIsLoading(false)
			})
	}

	const handleBasesFileSelected = (event) => {
		if (event.target?.files.length === 0) return;
		uploadBasesService(event.target.files[0])
			.then(r => {
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText("Bases carregades correctament")
				setShowSnackBar(true);
				loadData()
			})
			.catch(e => {
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText("Error carregant les bases. Només s'admet format PDF.")
				setShowSnackBar(true);
			})
	}


	const handleEmparellamentsFileSelected = (event) => {

		if (event.target?.files.length === 0) return;
		uploadEmparellamentsService(event.target.files[0])
			.then(r => {
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText("Emparellaments carregats correctament")
				setShowSnackBar(true);
				loadData()
			})
			.catch(e => {
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText("Error carregant els emparellaments. Només s'admet format PDF.")
				setShowSnackBar(true);
			})
	}



	const tabs = [
		{
			label: "Mediateca",
			value: 0,
			route: "0",
		},
		{
			label: "Afegeix",
			value: 1,
			route: "1",

		},
	]


	const handleOnTabChange = (value) => {
		value = parseInt(value);
		setSelectedTab(value);
	};


	const handleDesar = () => {
		setIsLoading(true)
		updateAudioFilesService(audioFiles)
			.then(res => {
				setAudioFiles(res)
				setIsLoading(false)
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText("Àudios desats correctament")
				setShowSnackBar(true);
			})
			.catch(err => {
				console.log("error fetching audio files", err)
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText("Error desant els àudios")
				setShowSnackBar(true);
			})
		setHasChanged(false)
	}

	const handleFileNameChange = (value, element) => {
		let newAudioFiles = [...audioFiles];
		const elementIndex = newAudioFiles.findIndex(el => el.id === element.id);
		newAudioFiles[elementIndex].filename = value;
		setAudioFiles(newAudioFiles);
		setHasChanged(true)
	}

	/*const dragDrop = () => {

		var droppedFile = false;

		$form.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
			e.preventDefault();
			e.stopPropagation();
		})
		.on('dragover dragenter', function() {
			$form.addClass('is-dragover');
		})
		.on('dragleave dragend drop', function() {
			$form.removeClass('is-dragover');
		})
		.on('drop', function(e) {
			droppedFile = e.originalEvent.dataTransfer.files;
		});

	}*/



	return (
		<BodyNewSeasonScreen>
			<Prompt
				when={hasChanged}
				message={(location, action) => {
					return location.pathname === window.location.pathname
						? true
						: `Tens canvis sense desar, segur que vols sortir?`
				}}
			/>
			{isLoading && <div style={{position: "absolute", top: '50%', left: '50%'}}><CircularProgress/></div>}
			<div style={{display: "flex", width: "40%", marginTop: "50px", justifyContent: "space-between"}}>

				<div>
					{basesFilePath ? <Link underline="always" href={basesFilePath} target={"_blank"}  style={{marginRight: "28px"}}>Bases</Link> : <span style={{marginRight: "28px"}}>Bases</span>}
					<Button
						variant="contained"
						component="label"
						style={{marginTop: 8}}
					>
						Seleccionar...
						<input
							type="file"
							hidden
							onChange={handleBasesFileSelected}
						/>
					</Button>
				</div>


				<div>
					{emparellamentsFilePath ? <Link underline="always" href={emparellamentsFilePath} target={"_blank"}  style={{marginRight: "28px"}}>Emparellaments</Link> : <span style={{marginRight: "28px"}}>Emparellaments</span>}

					<Button
						variant="contained"
						component="label"
						style={{marginTop: 8}}
					>
						Seleccionar...
						<input
							type="file"
							hidden
							onChange={handleEmparellamentsFileSelected}
						/>
					</Button>
				</div>

			</div>

			<Divider style={{marginTop: "20px", marginBottom: "16px"}}/>

			<div style={{marginTop: "20px", marginBottom: "10px"}}>
				<b>Àudios</b>
			</div>
			<div style={{
				height: "auto", minHeight: "400px",
				display: "flex", flexDirection: "column", justifyContent: "space-between"
			}}>
				<TabBar initialValue={0} tabs={tabs} onChange={(value) => handleOnTabChange(value)} currentValue={selectedTab}/>
				{selectedTab === 0 && <MediatecaTab tabs={tabs} handleOnTabChange={handleOnTabChange}
							  selectedTab={selectedTab}
							  handleFileNameChange={handleFileNameChange} audioFiles={audioFiles}
							  handleDesar={handleDesar} />}
				{selectedTab === 1 && <AfegeixTab loadData={loadData} />}
			</div>
			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>
		</BodyNewSeasonScreen>


	)
}

export default FitxersScreen;

