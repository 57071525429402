import React, {useEffect, useState} from "react";
import {Snackbar, Typography} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Body from "../../components/Layout/Body/Body";
import EditableTable from "../../components/composed/EditableTable/EditableTableComponent/EditableTable";
import {cleanRow} from "../../utils/utils";
import {usersColumns} from "./usersTableContent";
import {SNACKBAR_MUI_TYPES, SNACKBAR_STRINGS, SNACKBAR_TYPES} from "../../utils/constants";
import Alert from "@material-ui/lab/Alert";
import {getUsersService, updateUsersService} from "../../data/services/usersService";


const Usuaris = ({}) => {

	const [isLoading, setIsLoading] = useState(false);

	const [rows, setRows] = useState([]);

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("info");
	const [snackBarText, setSnackBarText] = useState("snackBar");

	const [hasChanged, setHasChanged] = useState(false);


	const loadData = () => {
		getUsersService()
			.then(res => {
				setRows(res.rows)
				setIsLoading(false)
			})
			.catch(err => {
				console.error("[LlistatUsersScreen] error fetching users rows", err)
				showSetupSnackBar(SNACKBAR_TYPES.ERROR_GET_DATA);
				setIsLoading(false)
			})
	}

	useEffect(() => {
		setIsLoading(true)
		loadData();
	}, [])

	const saveTable = () => {
		setIsLoading(true)
		updateUsersService(rows)
			.then(res => {
				loadData()
				showSetupSnackBar(SNACKBAR_TYPES.SUCCESS)
			})
			.catch(err => {
				showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
				console.error("[LlistatUsersScreen] error uploading users rows", err)
				setIsLoading(false)
			})
		setHasChanged(false)
	}

	const showSetupSnackBar = (type) => {

		switch (type) {
			case SNACKBAR_TYPES.SUCCESS:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.SUCCESS_CLIPBOARD:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS_CLIPBOARD)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_GET_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_SEND_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			default:
				setSnackBarType("info")
				setSnackBarText("default switch")
				setShowSnackBar(true)

		}
	}

	const handleEditedRows = (editedRows) => {
		setRows(editedRows)
		setHasChanged(true)
		//console.info("[SardanesScreen] edited rows",editedRows)
	}

	return (
		<Body>
			<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 16}}>
				<Typography variant={"h4"}>Gestió d'usuaris</Typography>
			</div>


			<div style={{display: 'flex', justifyContent: 'flex-end'}}>
				<Button color={'primary'} variant={'contained'} disabled={!hasChanged} onClick={saveTable}>Desar</Button>
			</div>
			<EditableTable
				rows={rows}
				columns={usersColumns}
				editable={true}
				deletable={false}
				insertable={true}
				onRowAdded={() => setHasChanged(true)}
				onRowDeleted={() => setHasChanged(true)}
				onEditedTable={(editedRows) => handleEditedRows(editedRows)}
				addNewRow={cleanRow}
				isLoading={isLoading}
			/>

			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>
		</Body>
	)
}

export default Usuaris;
