import {getCollaboradorsApi, updateCollaboradorsApi} from "../../api/editarTemporada/collaboradorsApi";
import {
	collaboradorsFormatterFromApi,
	collaboradorsFormatterToApi,
} from "../../formatters/editarTemporada/collaboradorsFormatter";

export const getCollaboradorsService = () => {
	return new Promise((resolve, reject) => {
		getCollaboradorsApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(collaboradorsFormatterFromApi(responseData))
				} else {
					throw new Error("[collaboradorsService] error getting col·laboradors rows data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
};

export const updateCollaboradorsService = (collaboradorsRows) => {
	return new Promise((resolve, reject) => {
		updateCollaboradorsApi(collaboradorsFormatterToApi(collaboradorsRows))
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(collaboradorsFormatterFromApi(responseData))
				} else {
					throw new Error("[collaboradorsService] Update col·laboradors rows error")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}
