import {fasesRegularsEliminatoriesMerger} from "../../formatters/FasesRegulars/FasesRegularsFormatter";
import {getCalendariApi} from "../../api/calendariApi";
import {getTipusEliminatoriesService} from "../editarTemporada/tipusEliminatoriesService";
import {calendariTipusEliminatoriesFormatterFromApi} from "../../formatters/editarTemporada/calendariSardanesFormatter";
import {
	getResultatsFaseRegularsApi,
	simulationFaseRegularApi,
	updateResultatsFaseRegularApi
} from "../../api/FasesRegulars/FasesRegularsApi";
import {
	faseRegularDetailFromatterFromAPI,
	faseRegularDetailFromatterToAPI
} from "../../formatters/FasesRegulars/FasesRegularsDetailFormatter";

export const getFasesRegularsService = () => {

	const promiseCalendari = new Promise((resolve, reject) => {
		getCalendariApi()
			.then(res => {
				console.log("promiseCalendari")
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(responseData)
				} else {
					throw new Error("[FasesRegularsService] error getting calendari rows data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})

	const promiseEliminatories = getTipusEliminatoriesService();


	return Promise.all([promiseCalendari, promiseEliminatories])
		.then(resValues => {
			let eliminatories = calendariTipusEliminatoriesFormatterFromApi(resValues[1].rows);
			let merged = fasesRegularsEliminatoriesMerger(resValues[0], eliminatories)
			return {rows: merged}

		})
		.catch(error => {
			console.log(error)
		})

}

export const getFasesRegularsResultsService = (idElim) => {
	return new Promise((resolve, reject) => {
		getResultatsFaseRegularsApi(idElim)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(faseRegularDetailFromatterFromAPI(responseData))
				} else {
					throw new Error("[FasesRegularsService] error getting results rows data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}


export const updateFasesRegularsResultsService = (data, idElim) => {
	return new Promise((resolve, reject) => {
		updateResultatsFaseRegularApi(faseRegularDetailFromatterToAPI(data, idElim))
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(responseData)
				} else {
					throw new Error("[updateFasesRegularsResultsService] error updating results rows data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}

export const simulationFasesRegularsService = (urlBase, day, month, year) => {
	return new Promise((resolve, reject) => {
		simulationFaseRegularApi(urlBase, day, month, year)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					const w = window.open('about:blank');
					w.document.open();
					w.history.pushState('', '', `/simulacio?dia=${day}&mes=${month}&any=${year}`);
					w.document.write(responseData.data);
					w.document.close();
				} else {
					throw new Error("Not able to make a simulation")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}
