import React, {useEffect, useRef, useState} from "react";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SardanesClassificades from "../../components/composed/SardanesClassificades/sardanesClassificades";
import ResultsConcert from "../../components/composed/ResultsConcert/resultsConcert";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Body from "../../components/Layout/Body/Body";
import {
	actionsVoteService,
	getConcertsService,
	getPassKeywordsService,
	updateConcertsService
} from "../../data/services/concertsService";
import {CircularProgress, Paper, Snackbar} from "@material-ui/core";
import Alert from "../../components/unit/Alert/Alert";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {simulationFasesRegularsService} from "../../data/services/FasesRegulars/FasesRegularsService";
import moment from "moment";


const dropdownOptions = [
	"OBRIR VOTACIÓ", "TANCAR VOTACIÓ", "INTRODUCCIÓ DE RESULTATS", "VEURE SIMULACIÓ"
]

const ConcertsScreen = () => {

	const [concertTitle, setConcertTitle] = useState("");
	const [date, setDate] = useState("");
	const [actionSelect, setActionSelect] = useState("");
	const [urlPdfResultats, setUrlPdfResultats] = useState("");
	const [keyword, setKeyword] = useState("");
	const [keywordList, setKeywordList] = useState([]);
	const [concertData, setConcertData] = useState("");

	//Modal de confirmació
	const [openModalActions, setOpenModalActions] = useState(false);
	const [safeKeywordModalAction, setSafeKeywordModalAction] = useState("");

	const [showResults, setShowResults] = useState(true);

	const [manualEnter, setManualEnter] = useState(false);

	const referenciaFill = useRef(null);

	const [isUpdating, setIsUpdating] = useState(false);

	//snackBars
	const [snackSuccess, setSnackSuccess] = useState(false)
	const [snackSuccessMsg, setSnackSuccessMsg] = useState("")
	const [snackError, setSnackError] = useState(false)
	const [snackErrorMsg, setSnackErrorMsg] = useState("")


	useEffect(() => {
		loadAll()
	}, [])

	const handleOpen = () => {
		setOpenModalActions(true);
	};

	const handleClose = () => {
		setOpenModalActions(false);
		setSafeKeywordModalAction("");
	};

	const handleActionSelect = (event) => {
		setActionSelect(event.target.value)
		if (event.target.value === "INTRODUCCIÓ DE RESULTATS") {
			setShowResults(true)
			setManualEnter(true)
		} else if (event.target.value === "VEURE SIMULACIÓ") {
			handleSimulation()
		} else {
			handleOpen();
		}
	}

	const handleSave = async () => {
		setIsUpdating(true)
		updateConcertsService(keyword)
			.then(res => {
				if (!showResults) {
					loadAll()
					setSnackSuccessMsg("S'han actualitzat les dades")
					setSnackSuccess(true);
					setIsUpdating(false)
				}
			})
			.catch(error => {
				setSnackErrorMsg("Error actualitzant dades. No s'han desat.")
				setSnackError(true);
				setIsUpdating(false)
				console.error(error)
			})

		showResults && referenciaFill.current.updateAll(() => setIsUpdating(false));
	}

	const handleKeyword = (event) => {
		setKeyword(event.target.value)
	}

	const handleSafeKeyword = (event) => {
		setSafeKeywordModalAction(event.target.value)
	}

	const loadAll = () => {
		getConcertsService()
			.then(res => {
				console.info("[ConcertsScreen] get concerts ok")
				setConcertTitle(res.nameConcert);
				setKeyword(res.keyword);
				setDate(res.date);
				setUrlPdfResultats(res.finalInfo.urlPdfGenerat)
				setConcertData(res.concertData)
			});

		getPassKeywordsService()
			.then(res => {
				console.info("[ConcertsScreen] get passKeywords ok")
				setKeywordList(res)
			})
			.catch(error => {
				console.log(error)
			})
		referenciaFill?.current?.loadAll();

	};

	const handleSimulation = () => {
		let date = moment(concertData.dConcert);
		simulationFasesRegularsService(concertData.simulacio, date.date(), date.month() + 1, date.year()).then(r => {
			console.log(r)
		})
	}

	const handleActionAccepted = () => {
		if (actionSelect === safeKeywordModalAction) {
			actionsVoteService(actionSelect)
				.then(res => {
					setSnackSuccessMsg("Acció " + actionSelect + " realitzada correctament")
					setSnackSuccess(true);
					setActionSelect("");
					loadAll()
				})
				.catch(error => {
					setSnackErrorMsg("L'acció " + actionSelect + " no s'ha pogut realitzar completament. \n" + error.response?.data?.error);
					setSnackError(true);
					setActionSelect("");
				})
			handleClose();
		} else {
			alert("La paraula clau no coincideix amb l'acció")
		}
	}


	const handlePublishResults = () => {
		setActionSelect("PUBLICAR RESULTATS");
		handleOpen();
	}

	return (
		<Body>
			<div style={{display: "flex", flex: 1, flexDirection: "column"}}>
				<div style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%"
				}}>

					<div style={{display: "flex", flexDirection: "row"}}>
						<div style={{display: "flex", flexDirection: "column", marginRight: 30}}>
							<span style={{fontSize: 22, fontWeight: "bold", color: "grey"}}>
								{concertTitle}
							</span>
							<span style={{fontSize: 14}}>
								{date}
							</span>
						</div>
						<FormControl variant="outlined">
							<InputLabel>Accions</InputLabel>
							<Select
								id="accions-concert"
								label="Accions"
								style={{width: 180}}
								value={actionSelect}
								onChange={handleActionSelect}
							>
								{dropdownOptions.map((option) => <MenuItem value={option}>{option}</MenuItem>)}

							</Select>
						</FormControl>
					</div>
					<div>
						{urlPdfResultats &&
							<Button onClick={() => window.open(urlPdfResultats, "_blank")} variant={"outlined"}
									color={'primary'} style={{marginRight: 8, height: 38}}>
								<div style={{
									display: 'flex',
									alignItems: 'center',
									margin: 4,
									justifyContent: 'space-between'
								}}>
									<PictureAsPdfIcon/> Descarregar PDF
								</div>
							</Button>
						}
						{urlPdfResultats && showResults &&
							<Button variant="outlined" color="primary" onClick={handlePublishResults}
									style={{marginRight: 8, height: 38}}>
								Publicar resultats
							</Button>}

						<Button variant="contained" color="primary" onClick={handleSave} style={{height: 38}}
								disabled={isUpdating}
								startIcon={isUpdating && <CircularProgress size={"1.3rem"}/>}>
							Desar
						</Button>
					</div>
				</div>
				<div style={{
					display: "flex",
					flexWrap: "wrap",
					flexDirection: "row",
					borderBottom: "2px solid",
					borderColor: "lightgrey",
					paddingBottom: 40
				}}>

					<FormControl variant="outlined" style={{
						marginTop: 40,
						marginRight: 80,
						width: 400,
						display: actionSelect !== 'INTRODUCCIÓ DE RESULTATS' ? 'flex' : 'none'
					}}>
						<InputLabel>Paraula clau de la votació</InputLabel>
						<Select
							id="accions-concert"
							label="Paraula clau de la votació"
							value={keyword}
							disabled={showResults}
							onChange={handleKeyword}
						>

							{keywordList.map((selector, index) => {
								return (
									<MenuItem value={selector}>{selector}</MenuItem>
								)
							})}

						</Select>
					</FormControl>
				</div>

				{showResults ? <ResultsConcert ref={referenciaFill}
											   setNumResults={(numResults) => {
												   if (!manualEnter) setShowResults(numResults > 0)
											   }}/> :
					<SardanesClassificades/>}

				<div dangerouslySetInnerHTML={{__html:`
					<p><span style="font-weight: 400;">Notes informatives:</span></p>
					<ul>
					<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">En fer clic a &ldquo;Publicar resultats&rdquo; es publicaran els resultats al web i s'enviaran als correus electr&ograve;nics registrats a &ldquo;Llistat d&rsquo;emissores&rdquo; i &ldquo;Llistat de col·laboradors.</span></li>
					</ul>
					<p>&nbsp;</p>
				`}} />

			</div>

			<Snackbar open={snackSuccess} autoHideDuration={6000} onClose={() => setSnackSuccess(false)}
					  anchorOrigin={{vertical: "top", horizontal: "right"}}>
				<Alert onClose={() => setSnackSuccess(false)} severity="success">{snackSuccessMsg}</Alert>
			</Snackbar>

			<Snackbar open={snackError} autoHideDuration={6000} onClose={() => setSnackError(false)}
					  anchorOrigin={{vertical: "top", horizontal: "right"}}>
				<Alert onClose={() => setSnackError(false)} severity="error">{snackErrorMsg}</Alert>
			</Snackbar>

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
				open={openModalActions}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={openModalActions}>
					<Paper style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						backgroundColor: "white",
						padding: 32
					}}>
						<p>Segur que vols realitzar l'acció següent?</p>
						<p style={{fontWeight: "bold", fontSize: 18}}>{actionSelect}</p>
						<p>Si és així, escriu <strong>{actionSelect}</strong> a continuació:</p>
						<TextField id="safe-keyword" label={actionSelect} variant="outlined"
								   onChange={handleSafeKeyword} value={safeKeywordModalAction} style={{width: 300}}/>
						<div style={{marginTop: 30}}>
							<Button variant="outlined" color="primary" onClick={handleClose} style={{marginRight: 40}}>
								Cancel·lar
							</Button>
							<Button variant="contained" color="primary" onClick={handleActionAccepted}>
								Acceptar
							</Button>
						</div>
					</Paper>
				</Fade>
			</Modal>

		</Body>
	)
};

export default ConcertsScreen;
