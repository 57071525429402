export const tipusEliminatoriesFormatterFromApi = (data) => {

	let r = []
	data.map((calRow) => {
		r.push(
			{
				id: calRow.idTipus,
				titol: calRow.titolTipus,
				tipus: calRow.nomLlargTipus,
				nom: calRow.nomCurtTipus,
				num_sardanes: calRow.nSardanes,
				sardanes_visibles: calRow.bVeureQuines === '1',
				vots_blanc: calRow.bVotsEnBlanc === '1',
				admetre_votacio: calRow.bAdmetVots === '1',
				mostrar_ordinal: calRow.bMostraOrdinal === '1',
				comentari: calRow.comentariNoVots,
			}
		)
	})

	return {

		rows: r,
	}
};

export const tipusEliminatoriesFormatterToApi = (eliminatoriesRows) => {

	let forApiRows = []
	eliminatoriesRows.map((row, index) => {
		forApiRows.push(
			{
				idTipus: index + 1,
				titolTipus: row.titol,
				nomLlargTipus: row.tipus,
				nomCurtTipus: row.nom,
				nSardanes: row.num_sardanes,
				bVeureQuines: row.sardanes_visibles ? 1 : 0,
				bVotsEnBlanc: row.vots_blanc ? 1 : 0,
				bAdmetVots: row.admetre_votacio ? 1 : 0,
				bMostraOrdinal: row.mostrar_ordinal ? 1 : 0,
				comentariNoVots: row.comentari,
			}
		)

	})
	console.info("forApiRows", forApiRows)
	return forApiRows
};
