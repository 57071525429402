import React, {useEffect, useState} from "react";
import BodyNewSeasonScreen from "../../components/composed/BodyNewSeason/BodyNewSeason";
import EditableTable from "../../components/composed/EditableTable/EditableTableComponent/EditableTable";
import {sardanesClassificadesColumns} from "./sardanesClassificadesTableContent";
import {Button, FormControlLabel, Snackbar, Switch} from "@material-ui/core";
import {SNACKBAR_MUI_TYPES, SNACKBAR_STRINGS, SNACKBAR_TYPES} from "../../utils/constants";
import Alert from "@material-ui/lab/Alert";
import {Prompt} from "react-router-dom";
import moment from "moment";
import {getSardanesClassificadesService} from "../../data/services/concertsService";
import {updateSardanesClassificadesService} from "../../data/services/editarTemporada/sardanesClassificadesService";
import {getSardanesService} from "../../data/services/editarTemporada/sardanesService";


const SardanesClassificadesScreen = () => {

	const [hasChanged, setHasChanged] = useState(false);

	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);
	const [editable, setEditable] = useState(true);

	const [autoSave, setAutoSave] = useState(true);
	const [lastSaved, setLastSaved] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("info");
	const [snackBarText, setSnackBarText] = useState("snackBar");


	useEffect(() => {
		loadData();
	}, [])

	const loadData = () => {
		setIsLoading(true)
		const sardanesClassificades = getSardanesClassificadesService()

		const sardanesDisponibles = getSardanesService()

		Promise.all([sardanesClassificades, sardanesDisponibles])
			.then(resultat => {
				const classificades = resultat[0];
				if (classificades.some(sardana => parseInt(sardana.nVots) !== 0)) setEditable(false);
				const disponibles = resultat[1]?.rows;
				const sardanesDisponiblesOptions = []
				disponibles.forEach(item => {
					if (!classificades.some(el => el.id === item.id)) {
						sardanesDisponiblesOptions.push({
							value: item.id,
							label: item.titol
						})
					}
				});
				const allSardanes = disponibles.map(item => ({
						value: item.id,
						label: item.titol
					}
				));
				const colTipusIndex = sardanesClassificadesColumns.findIndex(item => item.field === "idSardana")
				sardanesClassificadesColumns[colTipusIndex] = {
					...sardanesClassificadesColumns[colTipusIndex],
					valueOptions: sardanesDisponiblesOptions,
					allSardanes: allSardanes
				}
				console.log(sardanesClassificadesColumns);
				setColumns(sardanesClassificadesColumns)
				const resRows = classificades.map((item, index) => ({
					id: index + 1,
					nOrdre: index + 1,
					idSardana: item.id,
				}));
				setRows(resRows)
				setIsLoading(false)
			})
			.catch(err => {
				setIsLoading(false)
				console.error("[SardanesClassificadesScreen] error fetching collaboradors rows", err)
				showSetupSnackBar(SNACKBAR_TYPES.ERROR_GET_DATA)
			})
	}


	const saveTable = (silent = false, refreshContent = true, editedRows = null) => {
		if (!silent) setIsLoading(true)
		updateSardanesClassificadesService(editedRows ? editedRows : rows)
			.then(res => {
				loadData();
				setLastSaved("Últim desat a les " + moment().format("HH:mm:ss"))
			})
			.catch(err => {
				console.error("[SardanesClassificadesScreen] error uploading collaboradors rows", err)
				if (!silent) {
					showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
				}
				setIsLoading(false)
			})
		if (refreshContent) {
			setHasChanged(false)
		}
	}

	const showSetupSnackBar = (type) => {

		switch (type) {
			case SNACKBAR_TYPES.SUCCESS:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_GET_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_SEND_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			default:
				setSnackBarType("info")
				setSnackBarText("default switch")
				setShowSnackBar(true)

		}
	}

	const handleEditedRows = (editedRows) => {
		setRows(editedRows)
		setHasChanged(true)
		if (autoSave) saveTable(true, true, editedRows);
	}

	return (
		<BodyNewSeasonScreen>
			<Prompt
				when={hasChanged}
				message={(location, action) => {
					return location.pathname === window.location.pathname
						? true
						: `Tens canvis sense desar, segur que vols sortir?`
				}}
			/>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<FormControlLabel
					control={<Switch
						checked={autoSave}
						disabled={!editable}
						onChange={() => setAutoSave(!autoSave)}
						name="autosave"
						inputProps={{'aria-label': 'secondary checkbox'}}
					/>}
					label={"Autodesar " + lastSaved}
				/>

				<Button color={'primary'} variant={'contained'} disabled={!hasChanged}
						onClick={() => saveTable()}>Desar</Button>
			</div>
			<EditableTable
				rows={rows}
				columns={columns}
				editable={editable}
				deletable={editable}
				insertable={editable}
				onRowAdded={() => setHasChanged(true)}
				onRowDeleted={() => setHasChanged(true)}
				onEditedTable={(editedRows) => handleEditedRows(editedRows)}
				addNewRow={() => {
					if (rows.length > 0) {
						const lastRow = rows[rows.length - 1];
						return {
							id: lastRow.id + 1,
							nOrdre: lastRow.nOrdre + 1,
						}
					} else return {
						id: 1,
						nOrdre: 1
					}
				}}
				isLoading={isLoading}
				emptyRow={{
					id: "",
				}}
			/>

			<p><b>Instruccions:</b>
				<ul>
					<li>Aquesta pantalla serveix per definir les sardanes classificades del premi popular que
						s'interpretaran al concert final
					</li>
					<li>Ompliu la taula respectant l'ordre d'interpretació de les sardanes</li>
					<li>No es podrà modificar aquesta taula si ja s'ha intrduït algun resultat de votació</li>
				</ul>
			</p>

			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>

		</BodyNewSeasonScreen>


	)
};

export default SardanesClassificadesScreen;
