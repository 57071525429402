import React, {useEffect, useState} from "react";
import BodyNewSeasonScreen from "../../components/composed/BodyNewSeason/BodyNewSeason";
import EditableTable from "../../components/composed/EditableTable/EditableTableComponent/EditableTable";
import {collaboradorsColumns} from "./collaboradorsTableContent";
import {cleanRow} from "../../utils/utils";
import {Button, FormControlLabel, Snackbar, Switch} from "@material-ui/core";
import {
	getCollaboradorsService,
	updateCollaboradorsService
} from "../../data/services/editarTemporada/collaboradorsService";
import {SNACKBAR_MUI_TYPES, SNACKBAR_STRINGS, SNACKBAR_TYPES} from "../../utils/constants";
import Alert from "@material-ui/lab/Alert";
import {Prompt} from "react-router-dom";
import moment from "moment";


const LlistatCollaboradorsScreen = () => {

	const [hasChanged, setHasChanged] = useState(false);

	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);

	const [autoSave, setAutoSave] = useState(true);
	const [lastSaved, setLastSaved] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("info");
	const [snackBarText, setSnackBarText] = useState("snackBar");


	useEffect(() => {
		setIsLoading(true)
		getCollaboradorsService()
			.then(res => {
				setColumns(collaboradorsColumns)
				setRows(res.rows)
				setIsLoading(false)
			})
			.catch(err => {
				setIsLoading(false)
				console.error("[LlistatCollaboradorsScreen] error fetching collaboradors rows", err)
				showSetupSnackBar(SNACKBAR_TYPES.ERROR_GET_DATA)
			})
	}, [])


	const saveTable = (silent = false, refreshContent = true, editedRows = null) => {
		if (!silent) setIsLoading(true)
		updateCollaboradorsService(editedRows ? editedRows : rows)
			.then(res => {
				if (refreshContent) {
					setRows(res.rows)
				}
				if (!silent) {
					setIsLoading(false)
					showSetupSnackBar(SNACKBAR_TYPES.SUCCESS)
				}
				setLastSaved("Últim desat a les " + moment().format("HH:mm:ss"))
			})
			.catch(err => {
				console.error("[LlistatCollaboradorsScreen] error uploading collaboradors rows", err)
				if (!silent) {
					showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
				}
				setIsLoading(false)
			})
		if (refreshContent) {
			setHasChanged(false)
		}
	}


	const handleCopyToClipboard = () => {
		let emails = ""
		rows.map((row) => {
			if (row.email) {
				emails = emails + row.email + ', '
			}
		})
		//remove last comma and space and copy to clipboard
		//navigator.clipboard.writeText(emails.slice(0,-2)).then(r => console.log("Alguna cosa ha fallat", r))
		navigator.clipboard.writeText(emails.slice(0, -2)).then(function () {
			/* clipboard successfully set */
			showSetupSnackBar(SNACKBAR_TYPES.SUCCESS_CLIPBOARD)
			console.info("[LlistatCollaboradorsScreen] Clipboard write succesful")
		}, function () {
			/* clipboard write failed */
			console.error("[LlistatCollaboradorsScreen] Clipboard write failed")
		});
	}

	const showSetupSnackBar = (type) => {

		switch (type) {
			case SNACKBAR_TYPES.SUCCESS:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.SUCCESS_CLIPBOARD:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS_CLIPBOARD)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_GET_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_SEND_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			default:
				setSnackBarType("info")
				setSnackBarText("default switch")
				setShowSnackBar(true)

		}
	}

	const handleEditedRows = (editedRows) => {
		setRows(editedRows)
		setHasChanged(true)
		//console.info("[SardanesScreen] edited rows",editedRows)
		if (autoSave) saveTable(true, true, editedRows);
	}

	return (
		<BodyNewSeasonScreen>
			<Prompt
				when={hasChanged}
				message={(location, action) => {
					return location.pathname === window.location.pathname
						? true
						: `Tens canvis sense desar, segur que vols sortir?`
				}}
			/>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<FormControlLabel
					control={<Switch
						checked={autoSave}
						onChange={() => setAutoSave(!autoSave)}
						name="autosave"
						inputProps={{'aria-label': 'secondary checkbox'}}
					/>}
					label={"Autodesar " + lastSaved}
				/>

				<Button color={'primary'} variant={'contained'} disabled={!hasChanged}
						onClick={() => saveTable()}>Desar</Button>
			</div>
			<EditableTable
				rows={rows}
				columns={collaboradorsColumns}
				editable={true}
				deletable={true}
				insertable={true}
				onRowAdded={() => setHasChanged(true)}
				onRowDeleted={() => setHasChanged(true)}
				onEditedTable={(editedRows) => handleEditedRows(editedRows)}
				addNewRow={cleanRow}
				isLoading={isLoading}
				emptyRow={{
					id: "",
					nom: "",
					email: ""
				}}
			/>

			<Button
				variant={"outlined"}
				color={"secondary"}
				onClick={handleCopyToClipboard}
			>
				Copiar correus al porta retalls
			</Button>

			<p><b>Nota informativa:</b> tots els correus d’aquesta llista rebran els resultats de les diferents fases regulars de forma automàtica.</p>

			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>

		</BodyNewSeasonScreen>


	)
};

export default LlistatCollaboradorsScreen;
