import {CONCERT_ACTIONS} from "../../utils/constants";
import moment from "moment";

export const concertsFormatter = (data) => {
	return {
		nameConcert: data.res.txtTitolPrincipal,
		streamingURL: data.res.urlFinalStreaming,
		keyword: data.res.txtFinalParaulaClau,
		date: moment(data.res.dConcert).format("DD/MM/YYYY"),
		finalInfo: data.res.finalInfo,
		concertData: data.res
	}
};

export const updateConcertFormatterToApi = (data) => {
	return {
		paraula_pas_seleccionada: data.keyword
	}
}

export const getPassKeywordsFormatter = (data) => {
	return data
}

export const getSardanesClassificadesFormatter = (data) => {
	let sardanes = []
	data.forEach(sardana => {
		sardanes.push({
			id: sardana.idSardana,
			name: sardana.Titol,
			nVots: sardana.nVots
		})
	})
	return sardanes;
}

export const actionsVoteFormatterToApi = (actionType) => {
	if (actionType === "TANCAR VOTACIÓ") {
		return CONCERT_ACTIONS.CLOSE
	} else if (actionType === "OBRIR VOTACIÓ") {
		return CONCERT_ACTIONS.OPEN
	} else {
		return CONCERT_ACTIONS.PUBLISH
	}
}
