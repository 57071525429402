import {getResultatsConcertsApi, updateResultatsConcertsApi} from "../api/resultatsConcertsApi";
import {
	getInfoVotsFormatter,
	getResultatsConcertsFormatter,
	updateResultatsConcertsFormatter
} from "../formatters/resultatsConcertsFormatter";


export const getResultatsConcertsService = (concertType) => {
	return new Promise((resolve, reject) => {
		getResultatsConcertsApi(concertType)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) {
						let infoVots = null;
						if (responseData.infoVots) {
							infoVots = getInfoVotsFormatter(responseData.infoVots)
						}
						let resolveRes = {res: getResultatsConcertsFormatter(responseData.res), infoVots};
						resolve(resolveRes)
					}
				} else {
					throw new Error("Error getting concerts data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}

export const updateResultatsConcertsService = (sardanes, totalVots, nullVots, jurat = "") => {
	return new Promise((resolve, reject) => {
		updateResultatsConcertsApi(updateResultatsConcertsFormatter(sardanes), totalVots, nullVots, jurat.trim())
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) {
						let resolveRes = responseData.res;
						let formatted = {
							sardanesPremiPopular: getResultatsConcertsFormatter(resolveRes.sardanesPremiPopular.res),
							sardanesPremiCritica: getResultatsConcertsFormatter(resolveRes.sardanesPremiCritica.res),
							sardanesPremiJoventut: getResultatsConcertsFormatter(resolveRes.sardanesPremiJoventut.res),
							finalInfo: resolveRes.finalInfo
						}
						resolve(formatted)
					}
				} else {
					throw new Error("Error getting concerts data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})

}


