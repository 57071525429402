import moment from "moment";

export const dadesTemporadaColumns = [
	{field: "id", headerName: "ID", hide: true},
	{
		field: "titol_principal",
		width: 220,
		headerName: "Titol principal",
		type: "string",
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
		sortable: false,
	},
	{
		field: "subtitol",
		width: 220,
		headerName: "Subtítol",
		type: "string",
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
		sortable: false,
	},
	{
		field: "any_lsda",
		width: 120,
		headerName: "Any LSDA",
		type: "string",
		headerAlign: 'left',
		align: 'left',
		disableColumnMenu: true,
		editable: false,
		sortable: false,
	},
	{
		field: "supersardana",
		width: 120,
		headerName: "SuperSardana",
		description: "SuperSardana",
		type: "boolean",
		headerAlign: 'left',
		align: 'center',
		sortable: false,
		disableColumnMenu: true,
	},
	{
		field: "data_inici",
		width: 120,
		headerName: "Data d'inici",
		sortable: false,
		filterable: false,
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		type: 'date',
		valueFormatter: (params) => {
			if (!params.value) return "";
			const valueFormatted = moment(params.value).format("dd. DD/MM/YYYY")
			return `${valueFormatted}`;
		},
	},


	{
		field: "data_final_elim",
		width: 120,
		headerName: "Data final",
		sortable: false,
		filterable: false,
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		type: 'date',
		valueFormatter: (params) => {
			if (!params.value) return "";
			const valueFormatted = moment(params.value).format("dd. DD/MM/YYYY")
			return `${valueFormatted}`;
		},

	},
	{
		field: "data_inici_registre",
		width: 120,
		headerName: "Data d'inici registre",
		description: "Data d'inici registre",
		sortable: false,
		filterable: false,
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		type: 'date',
		valueFormatter: (params) => {
			if (!params.value) return "";
			const valueFormatted = moment(params.value).format("dd. DD/MM/YYYY")
			return `${valueFormatted}`;
		},
	},
	{
		field: "data_final_registre",
		width: 140,
		headerName: "Data final registre",
		description: "Data final registre",
		sortable: false,
		filterable: false,
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		type: 'date',
		valueFormatter: (params) => {
			if (!params.value) return "";
			const valueFormatted = moment(params.value).format("dd. DD/MM/YYYY")
			return `${valueFormatted}`;
		},
	},
	{
		field: "data_concert",
		width: 140,
		headerName: "Data concert",
		sortable: false,
		filterable: false,
		disableReorder: true,
		type: 'date',
		headerAlign: 'left',
		align: 'center',
		disableColumnMenu: true,
		valueFormatter: (params) => {
			const valueFormatted = moment(params.value).format("dd. DD/MM/YYYY")
			return `${valueFormatted}`;
		},
	},
	{
		field: "url_final_tickets",
		width: 260,
		headerName: "URL final tickets",
		type: "string",
		sortable: false,
	},
	{
		field: "url_final_streaming",
		width: 260,
		headerName: "URL final streaming",
		type: "string",
		sortable: false,
	},
];

export const dadesTemporadaRows = [
	{
		id_temporada: 1,
		titol_principal: "La sardana d'Orwell",
		subtitol: "Barcelona",
		any_lsda: "1984",
		supersardana: {
			value: false,
			onChange: null
		},
		data_inici: "24/05/1984",
		data_final_elim: "24-05-1984",
		data_inici_registre: "1984/05/05",
		data_final_registre: "24/05/1984",
		data_concert: "24/05/1984",
		url_final_tickets: "http://bcn-orwell.com",
		url_final_streaming: "Ladorwell.cat",

	},
];

export const dadesTemporadaRowsFromApi = {
	res : [
			{
				id : 1,
				txtTitolPrincipal: "La sardana d'Orwell",
				txtSubtitol: "Barcelona",
				txtAnyLSDA: "1984",
				bSupersardana: "1",
				dInici: "24/05/1984",
				dFinalEliminatories: "25/05/1984",
				dIniciRegistre: "26/05/1984",
				dFinalRegistre: "27/05/1984",
				dConcert: "28/05/1984",
				urlFinalTickets: "http://bcn-orwell.com",
				urlFinalStreaming: "http://ladorwell.com",
			}
	]
};
