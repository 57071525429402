import {
	getAudioFilesApi,
	getBasesEmparellamentsApi,
	putAudioFilesApi,
	uploadAudioFileApi,
	uploadBasesFileApi,
	uploadEmparellamentsFileApi
} from "../../api/editarTemporada/fitxersApi";
import {audioFilesFormatterFromApi, audioFilesFormatterToApi,} from "../../formatters/editarTemporada/fitxersFormatter";

export const getAudioFilesService = () => {
	return new Promise((resolve, reject) => {
		getAudioFilesApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(audioFilesFormatterFromApi(responseData))
				} else {
					throw new Error("[FitxersService] Error getting data")
				}
			})
			.catch(error => {
				console.error("[FitxersService]", error)

				reject(error.toString())
			})
	})
};

export const uploadAudioService = (file) => {
	return new Promise((resolve, reject) => {
		uploadAudioFileApi(file)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData)
				} else {
					throw new Error("[FitxersService] Error carregant audio")
				}
			})
			.catch(error => {
				console.error("[FitxersService]", error)
				reject(error.toString())
			})
	})
};

export const uploadBasesService = (file) => {
	return new Promise((resolve, reject) => {
		uploadBasesFileApi(file)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData)
				} else {
					throw new Error("[FitxersService] Error carregant bases")
				}
			})
			.catch(error => {
				console.error("[FitxersService]", error)
				reject(error.toString())
			})
	})
};

export const uploadEmparellamentsService = (file) => {
	return new Promise((resolve, reject) => {
		uploadEmparellamentsFileApi(file)
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData)
				} else {
					throw new Error("[FitxersService] Error carregant emparellaments")
				}
			})
			.catch(error => {
				console.error("[FitxersService]", error)
				reject(error.toString())
			})
	})
};

export const getBasesEmparellamentsService = () => {
	return new Promise((resolve, reject) => {
		getBasesEmparellamentsApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(responseData.res)
				} else {
					throw new Error("[FitxersService] Error getting data")
				}
			})
			.catch(error => {
				console.error("[FitxersService]", error)
				reject(error.toString())
			})
	})
};

export const updateAudioFilesService = (rows) => {
	return new Promise((resolve, reject) => {
		putAudioFilesApi(audioFilesFormatterToApi(rows))
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data;
					if (responseData) resolve(audioFilesFormatterFromApi(responseData))
				} else {
					throw new Error("[FitxersService] Error desant")
				}
			})
			.catch(error => {
				console.error("[FitxersService]", error)
				reject(error.toString())
			})
	})
}
