import {getTipusEliminatoriesApi, updateTipusEliminatoriesApi} from "../../api/editarTemporada/tipusEliminatoriesApi";
import {
	tipusEliminatoriesFormatterFromApi,
	tipusEliminatoriesFormatterToApi
} from "../../formatters/editarTemporada/tipusEliminiatoriesFormatter";

export const getTipusEliminatoriesService = () => {
	return new Promise((resolve, reject) => {
		getTipusEliminatoriesApi()
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(tipusEliminatoriesFormatterFromApi(responseData))
				} else {
					throw new Error("[TipusEliminatoriesService] Error getting data")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
};

export const updateTipusEliminatoriesService = (tipusEliminatoriesRows) => {
	return new Promise((resolve, reject) => {
		updateTipusEliminatoriesApi(tipusEliminatoriesFormatterToApi(tipusEliminatoriesRows))
			.then(res => {
				if (res.status === 200) {
					const responseData = res.data.res;
					if (responseData) resolve(tipusEliminatoriesFormatterFromApi(responseData))
				} else {
					throw new Error("[TipusEliminatoriesService] Error update eliminatories")
				}
			})
			.catch(error => {
				console.error(error)
				reject(error.toString())
			})
	})
}
