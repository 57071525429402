import React, {useEffect, useState} from "react";
import Body from "../../components/Layout/Body/Body";
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import {Paper, Snackbar} from "@material-ui/core";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import {createNovaTemporadaService} from "../../data/services/novaTemporada/novaTemporadaService";
import {SNACKBAR_MUI_TYPES, SNACKBAR_STRINGS, SNACKBAR_TYPES} from "../../utils/constants";
import Alert from "@material-ui/lab/Alert";

const AddSeasonScreen = ({history}) => {

	useEffect(() => {
		let newYearsArray = []
		for (let i = currentYear-1; i < currentYear + 8; i++) {
			newYearsArray.push(i)
		}
		setYearsArray(newYearsArray)
	}, [])

	let currentYear = moment().year();
	const [yearsArray, setYearsArray] = useState([])
	const [openModal, setOpenModal] = useState(false);
	const [year, setYear] = useState("");

	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackBarType, setSnackBarType] = useState("info");
	const [snackBarText, setSnackBarText] = useState("snackBar");

	const handleYear = (e) => {
		setYear(e.target.value)
	};

	const handleOpenModal = () => {
		setOpenModal(true)
	};

	const handleCloseModal = () => {
		setOpenModal(false)
	};

	const handleActionAccepted = () => {
		createNovaTemporadaService(year)
			.then(res => {
				showSetupSnackBar(SNACKBAR_TYPES.SUCCESS_NEW_TEMPORADA);
				history.push('/editar-temporada/dades-temporada');
			})
			.catch(err => {
				showSetupSnackBar(SNACKBAR_TYPES.ERROR_SEND_DATA)
				console.error("[AddSeasonScreen]Error fetching temporades", err)
			})
	};

	const showSetupSnackBar = (type) => {

		switch (type) {
			case SNACKBAR_TYPES.SUCCESS_NEW_TEMPORADA:
				setSnackBarType(SNACKBAR_MUI_TYPES.SUCCESS)
				setSnackBarText(SNACKBAR_STRINGS.SUCCESS_NEW_TEMPORADA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_GET_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_GET_DATA)
				setShowSnackBar(true)
				break

			case SNACKBAR_TYPES.ERROR_SEND_DATA:
				setSnackBarType(SNACKBAR_MUI_TYPES.ERROR)
				setSnackBarText(SNACKBAR_STRINGS.ERROR_SEND_DATA)
				setShowSnackBar(true)
				break

			default:
				setSnackBarType("info")
				setSnackBarText("default switch")
				setShowSnackBar(true)

		}
	}

	return (
		<Body>
			<h1>Creació de temporada</h1>
			<h4>Aquest assistent donarà suport a la creació d'una nova temporada. Completa la informació següent:</h4>

			<p>Generalment, les temporades de La Sardana de l'Any van un any enrere. Per exemple, l'any 2022 es guardona la sardana de l'any del 2021. En aquest desplegable cal seleccionar l'any de la sardana de que es vol guardonar, i no l'any en el qual es farà el concert.</p>
			<div style={{marginTop: 40, marginBottom: 40}}>
				<FormControl>
					<InputLabel>Any de la temporada</InputLabel>
					<Select value={year} onChange={handleYear} style={{width: 180}}>
						{yearsArray.map(year => {
							return <MenuItem value={year}>{year}</MenuItem>
						})}
					</Select>
				</FormControl>
			</div>

			<p>Al crear la temporada, alguns apartats es preomplen les dades de la temporada anterior. Es podran editar un cop creada la temporada.</p>

			<Button disabled={year === null} variant="contained" color="primary" onClick={handleOpenModal}>
				Crear temporada
			</Button>

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
				open={openModal}
				onClose={handleCloseModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={openModal}>
					<Paper style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						backgroundColor: "white",
						padding: 32
					}}>
						<h2>Segur que vols crear la temporada?</h2>
						<div>
							<Button variant="outlined" color="primary" onClick={handleCloseModal}
									style={{marginRight: 40}}>
								Cancel·lar
							</Button>
							<Button variant="contained" color="primary" onClick={handleActionAccepted}>
								Acceptar
							</Button>
						</div>
					</Paper>
				</Fade>
			</Modal>
			<Snackbar open={showSnackBar} autoHideDuration={5000} onClose={() => setShowSnackBar(false)}>
				<Alert severity={snackBarType} onClose={() => setShowSnackBar(false)}>
					{snackBarText}
				</Alert>
			</Snackbar>
		</Body>
	)
};

export default AddSeasonScreen
