import {IconButton} from "@material-ui/core";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";

const DeleteButton = ({params, onClick}) => {

	return (
		<IconButton onClick={() => {
			onClick(params)
		}}>
			<DeleteIcon/>
		</IconButton>
	);
};

export default DeleteButton;
